import { BaseLevel, Level } from 'common/types/API';
import { create } from 'zustand';

interface RangeFilter {
    property: keyof BaseLevel;
    range: {
        min?: number;
        max?: number;
    };
}

interface TagsFilter {
    [key: string]: boolean;
}

interface ListFilters {
    ranges: RangeFilter[];
    tags: TagsFilter;
}

interface ListState {
    selectedLevel: string;
    listType: string;
    sort: string;
    filters: ListFilters;
    selectLevel: (id: string, levels: Level[]) => boolean;
    setListType: (value: string) => void;
    setSort: (value: string) => void;
    setRange: (property: keyof BaseLevel, min?: number, max?: number) => void;
    setTags: (tags: TagsFilter) => void;
}

const useListStore = create<ListState>((set) => ({
    selectedLevel: '',
    isSelected2P: false,
    listType: 'classic',
    sort: 'rank',
    filters: {
        ranges: [
            {
                property: 'position',
                range: {
                    min: undefined,
                    max: undefined,
                },
            },
            {
                property: 'edel_enjoyment',
                range: {
                    min: undefined,
                    max: undefined,
                },
            },
            {
                property: 'gddl_tier',
                range: {
                    min: undefined,
                    max: undefined,
                },
            },
        ],
        tags: {},
    },
    selectLevel: (id, levels) => {
        if (levels.find((level) => level.id === id) != undefined) {
            set({
                selectedLevel: id,
            });
            return true;
        } else {
            return false;
        }
    },
    setListType: (value) => set((state) => ({ ...state, listType: value })),
    setSort: (value) => set((state) => ({ ...state, sort: value })),
    setRange: (property, min, max) =>
        set((state) => {
            const updatedRanges = state.filters.ranges.map((rangeFilter) => {
                if (rangeFilter.property === property) {
                    return {
                        ...rangeFilter,
                        range: {
                            min: min,
                            max: max,
                        },
                    };
                }
                return rangeFilter;
            });

            return {
                filters: {
                    ...state.filters,
                    ranges: updatedRanges,
                },
            };
        }),
    setTags(tags) {
        set((state) => {
            return {
                filters: {
                    ...state.filters,
                    tags: tags,
                },
            };
        });
    },
}));

export default useListStore;
