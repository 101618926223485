import { create } from 'zustand';
interface BackgroundState {
    background: string;
    opacity: string;
    grayscale: string;
    setBackground: (image: string, opacity: string, grayscale: string) => void;
    resetBackground: () => void;
}
const useBackground = create<BackgroundState>((set) => ({
    background: '/assets/background.png',
    opacity: '1',
    grayscale: '0%',
    setBackground: (image: string, opacity: string, grayscale: string) => {
        set({ background: image, opacity: opacity, grayscale: grayscale });
    },
    resetBackground: () => {
        set({
            background: '/assets/background.png',
            opacity: '1',
            grayscale: '0%',
        });
    },
}));

export default useBackground;
