import styled from 'styled-components';
import colors from 'utils/style/colors';
import { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface WrapperProps {
    width: string;
    height: string;
}

interface CardWrapperProps {
    children: ReactNode;
    className?: string;
    width?: string;
    height?: string;
}

const Wrapper = styled.section<WrapperProps>`
    display: flex;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    background-color: ${colors.backgroundDark};
    padding: 0 1rem 1rem 1rem;
    margin: 0;
    border-radius: 1.5rem;
    overflow-y: scroll;
    overflow-x: clip;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 0;
    }

    scrollbar-width: none;
`;

const CardWrapper: React.FC<CardWrapperProps> = ({
    children,
    width = '100%',
    height = '80vh',
    className = '',
}) => {
    return (
        <Wrapper width={width} height={height} className={className}>
            {children}
        </Wrapper>
    );
};

CardWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default CardWrapper;
