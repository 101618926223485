import { useBackground, useListStore } from 'common/stores';
import ScrollWrapper from 'components/Layout/ScrollWrapper';
import LevelInfo from 'components/List/LevelInfo';
import Levels from 'components/List/Levels';
import RecordsInfo from 'components/List/RecordsInfo';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useLevels } from 'utils/publicQueries';

const ListPageWrapper = styled.main`
    display: grid;
    height: fit-content;
    align-self: center;

    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 1rem;

    @media (max-width: 1000px) {
        display: flex;
        margin: 1rem auto;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scroll-padding: 0 1rem;
    }

    & > section {
        display: flex;

        @media (max-width: 1000px) {
            scroll-snap-align: center;
            flex: 0 0 100%;
        }
    }
`;

function List() {
    const { selectedLevel, listType } = useListStore();
    const { data, isLoading } = useLevels(listType);
    const { resetBackground, setBackground } = useBackground();
    const level = data?.find((level) => level.id === selectedLevel);
    useEffect(() => {
        if (!isLoading && level) {
            setBackground(
                `https://raw.githubusercontent.com/cdc-sys/level-thumbnails/main/thumbs/${level.level_id}.png`,
                '0.05',
                '100%',
            );
        }
    }, [selectedLevel]);

    // Cleanup
    useEffect(() => {
        return resetBackground;
    }, []);

    return (
        <ScrollWrapper $scrolldirection="horizontal">
            <ListPageWrapper>
                <Levels />
                <LevelInfo />
                <RecordsInfo />
            </ListPageWrapper>
        </ScrollWrapper>
    );
}

export default List;
