import React from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';

const RadioGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;

    div {
        padding-bottom: 0.5rem;
    }

    label {
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: 1.15rem;
        color: ${colors.primary};
        vertical-align: middle;
        cursor: pointer;
    }

    input[type='radio'] {
        width: 1.25rem;
        height: 1.25rem;
        accent-color: ${colors.secondary};
        vertical-align: middle;
        cursor: pointer;
    }
`;

interface RadioOption {
    label: string;
    value: string;
}

interface RadioGroupProps {
    name: string;
    options: RadioOption[];
    selectedValue: string;
    onChange: (value: string) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
    name,
    options,
    selectedValue,
    onChange,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <RadioGroupWrapper>
            {options.map((option) => (
                <div key={option.value}>
                    <input
                        type="radio"
                        id={option.value}
                        name={name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={handleChange}
                    />
                    <label htmlFor={option.value}>{option.label}</label>
                </div>
            ))}
        </RadioGroupWrapper>
    );
};

export default RadioGroup;
