import { useMemo, useCallback } from 'react';
import { Loader } from 'components/Others/Loader';
import styled from 'styled-components';
import ErrorComponent from 'components/Others/Error';
import { usePacks, useProfile } from 'utils/publicQueries';
import ValueSwitch from 'components/Layout/ValueSwitch';
import useProfileStore from 'common/stores/profile';
import ScrollWrapper from 'components/Layout/ScrollWrapper';
import LevelCard from './LevelCard';
import PackCard from './PackCard';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { BaseLevel, ProfileRecord, ProfilePack } from 'common/types/API';

const ProfileMainWrapper = styled.section`
    width: 65%;
    padding: 0 2.5%;

    @media (max-width: 1200px) {
        width: 100%;
        text-align: center;
    }
    p {
        width: 100%;
        max-height: 10%;
        text-align: justify;
        @media (max-width: 1200px) {
            text-align: center;
        }
    }

    .switch {
        width: 90%;
        justify-content: flex-start;
        @media (max-width: 1200px) {
            justify-content: center;
            width: 60%;
            margin: auto;
            margin-bottom: 1rem;
        }
    }
`;

interface DisplayableProfileRecord extends ProfileRecord {
    displayType: 'completed' | 'verifier';
}
interface DisplayableBaseLevel extends BaseLevel {
    displayType: 'creator' | 'publisher' | 'publisher & creator';
}
interface DisplayableProfilePack extends ProfilePack {
    displayType: 'pack';
}
type DisplayableItem =
    | DisplayableProfileRecord
    | DisplayableBaseLevel
    | DisplayableProfilePack;

interface ItemData {
    items: DisplayableItem[];
}

const itemKey = (index: number, data: ItemData) => {
    const { items } = data;
    const item = items[index];
    return `${item.id}-${index}`;
};

function ProfileMain() {
    const {
        data: profileData,
        isLoading: isProfileLoading,
        isError: isProfileError,
        error: profileError,
    } = useProfile();

    const {
        data: packsData,
        isLoading: isPacksLoading,
        isError: isPacksError,
    } = usePacks();

    const { setLevelDisplayType, levelDisplayType } = useProfileStore();

    // Get profile data
    const combinedItems = useMemo((): DisplayableItem[] => {
        if (!profileData) return [];
        // Combine completed and verified
        const completedAndVerified = [
            ...(profileData.records ?? []).map((record) => ({
                ...record,
                displayType: 'completed',
            })),
            ...(profileData.verified ?? []).map((record) => ({
                ...record,
                displayType: 'verifier',
            })),
        ];

        // Combine created and published
        const created = (profileData.created ?? []).map((level) => ({
            ...level,
            displayType: 'creator',
        }));
        const published = (profileData.published ?? []).map((level) => ({
            ...level,
            displayType: 'publisher',
        }));

        // Filter out duplicates and mark as publisher&creator
        const levelMap = new Map();
        [...created, ...published].forEach((level) => {
            if (levelMap.has(level.id)) {
                levelMap.set(level.id, {
                    ...level,
                    displayType: 'publisher & creator',
                });
            } else {
                levelMap.set(level.id, level);
            }
        });
        const createdAndPublished = Array.from(levelMap.values());

        // Combine all levels
        const allLevels = [
            ...completedAndVerified,
            ...createdAndPublished,
        ].sort(
            (a, b) =>
                ('position' in a ? a.position : a?.level.position ?? 0) -
                ('position' in b ? b.position : b?.level.position ?? 0),
        );

        // Load packs
        const allPacks = (profileData.packs ?? []).map((pack) => ({
            ...pack,
            displayType: 'pack',
        }));

        // Load additional packs data
        if (!isPacksLoading && !isPacksError && packsData) {
            packsData.forEach((tier) => {
                tier.packs.forEach((pack) => {
                    const packIndex = allPacks.findIndex(
                        (p) => p.id === pack.id,
                    );
                    if (packIndex !== -1) {
                        allPacks[packIndex].points = pack.points;
                        allPacks[packIndex].levels = pack.levels;
                    }
                });
            });
            allPacks.sort((a, b) => {
                if (a.points && b.points) return b.points - a.points;
                else return 0;
            });
        }

        // Combine levels and packs
        const combinedItems = [...allLevels, ...allPacks];

        return combinedItems;
    }, [profileData, packsData]);

    // Filter the requested items
    const filteredItems = useMemo(() => {
        if (levelDisplayType === 'packs') {
            return combinedItems.filter((item) => item.displayType === 'pack');
        } else if (levelDisplayType === 'completed') {
            return combinedItems.filter(
                (item) =>
                    item.displayType === 'completed' ||
                    item.displayType === 'verifier',
            );
        } else if (levelDisplayType === 'created') {
            return combinedItems.filter(
                (item) =>
                    item.displayType === 'creator' ||
                    item.displayType === 'publisher' ||
                    item.displayType === 'publisher & creator',
            );
        } else return combinedItems;
    }, [combinedItems, levelDisplayType]);

    // Render a single item for the list component
    const renderCard = useCallback(
        ({ index, style }: ListChildComponentProps<ItemData>) => {
            const item = filteredItems[index];

            return (
                <div style={style}>
                    {item.displayType === 'pack' ? (
                        <PackCard
                            pack={item as ProfilePack}
                            display={item.displayType}
                        />
                    ) : (
                        <LevelCard
                            record={
                                item.displayType === 'completed' ||
                                item.displayType === 'verifier'
                                    ? (item as ProfileRecord)
                                    : undefined
                            }
                            level={
                                item.displayType === 'creator' ||
                                item.displayType === 'publisher' ||
                                item.displayType === 'publisher & creator'
                                    ? (item as BaseLevel)
                                    : undefined
                            }
                            display={item.displayType}
                        />
                    )}
                </div>
            );
        },
        [filteredItems],
    );

    // Get counts
    const { completedCount, createdCount, packsCount } = useMemo(() => {
        const completedCount =
            (profileData?.records?.filter((record) => !record.level.legacy)
                .length ?? 0) +
            (profileData?.verified?.filter((record) => !record.level.legacy)
                .length ?? 0);
        const createdCount =
            (profileData?.created?.length ?? 0) +
            (profileData?.published?.length ?? 0);
        const packsCount = profileData?.packs?.length ?? 0;
        return { completedCount, createdCount, packsCount };
    }, [profileData]);

    return (
        <>
            {isProfileLoading ? (
                <Loader />
            ) : !profileData ? (
                <Loader />
            ) : isProfileError ? (
                profileError instanceof Error ? (
                    <ErrorComponent code="404" message={profileError.message} />
                ) : (
                    ''
                )
            ) : (
                <ProfileMainWrapper>
                    <h3>ABOUT ME</h3>
                    <p>
                        {profileData.description ??
                            "This user hasn't provided a description yet"}
                    </p>
                    <ValueSwitch
                        className="switch"
                        set={setLevelDisplayType}
                        current={levelDisplayType}
                        options={['completed', 'created', 'packs']}
                        optionsDisplay={[
                            `Completed (${completedCount})`,
                            `Created (${createdCount})`,
                            `Packs (${packsCount})`,
                        ]}
                    />
                    <ScrollWrapper height="55vh">
                        <List
                            height={550}
                            itemCount={filteredItems.length}
                            itemSize={90}
                            width="100%"
                            itemData={{
                                items: filteredItems,
                            }}
                            itemKey={itemKey}
                        >
                            {renderCard}
                        </List>
                    </ScrollWrapper>
                </ProfileMainWrapper>
            )}
        </>
    );
}

export default ProfileMain;
