/* eslint-disable react/prop-types */
import { Submission } from 'common/types/API';
import StyledButton from 'components/Layout/Button';
import { Dropdown, DropdownItem } from 'components/Layout/Dropdown';
import Modal from 'components/Layout/Modal';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { SubmissionData } from './Submit';
import { StyledInput } from 'components/Layout/Input';

interface SubmissionModalProps {
    isOpen: boolean;
    submission: Submission;
    onSubmit: (data: SubmissionData) => void;
    onClose: () => void;
}

const SubmissionInfo = styled.div`
    width: 80%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background: ${colors.pendingTransparent};
    border: ${colors.pending} solid 0.1rem;

    &.rejected {
        border: ${colors.cancel} solid 0.1rem;
        background: ${colors.cancelTransparent};
    }
`;

const InputGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
`;

const EditSubmissionModal: React.FC<SubmissionModalProps> = ({
    isOpen,
    submission,
    onSubmit,
    onClose,
}) => {
    const focusInputRef = useRef<HTMLInputElement | null>(null);
    const [formState, setFormState] = useState<SubmissionData>({
        level: submission.level.id,
        video_url: submission.video_url,
        mobile: submission.mobile ?? false,
        ldm_id: submission.ldm_id ?? null,
        raw_footage: submission.raw_footage ?? null,
        additional_notes: submission.additional_notes ?? null,
    });
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ): void => {
        const { name, value } = event.target;
        setFormState((prevFormData) => ({
            ...prevFormData,
            [name]: value === '' ? null : value,
        }));
    };

    const handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        onSubmit(formState);
    };

    useEffect(() => {
        if (isOpen && focusInputRef.current) {
            setTimeout(() => {
                if (focusInputRef.current) focusInputRef.current.focus();
            }, 0);
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h1>{submission.level.name}</h1>
            <p>Last update: {submission.updated.split(' ')[0]}</p>
            <SubmissionInfo className={submission.rejected ? 'rejected' : ''}>
                {submission.rejected ? (
                    <p>
                        This record was rejected for the following reason:
                        <br />
                        {submission.rejection_reason ?? 'No reason provided'}
                    </p>
                ) : (
                    <p>This record is still pending</p>
                )}
            </SubmissionInfo>
            <h2>Edit submission data</h2>
            <form onSubmit={handleSubmit}>
                <InputGroup>
                    <StyledInput
                        ref={focusInputRef}
                        type="url"
                        id="video_url"
                        name="video_url"
                        placeholder="VIDEO LINK"
                        value={formState.video_url}
                        onChange={handleInputChange}
                        required
                    />
                    <Dropdown
                        buttonText={formState.mobile ? 'Mobile' : 'PC'}
                        content={
                            <>
                                <DropdownItem
                                    onClick={() =>
                                        setFormState((prevFormData) => ({
                                            ...prevFormData,
                                            mobile: false,
                                        }))
                                    }
                                >
                                    PC
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        setFormState((prevFormData) => ({
                                            ...prevFormData,
                                            mobile: true,
                                        }))
                                    }
                                >
                                    Mobile
                                </DropdownItem>
                            </>
                        }
                    />
                </InputGroup>
                <InputGroup>
                    <StyledInput
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        id="ldm_id"
                        placeholder="LDM ID (OPTIONAL)"
                        name="ldm_id"
                        value={formState.ldm_id ?? ''}
                        onChange={handleInputChange}
                    />
                    <StyledInput
                        type="url"
                        id="raw_footage"
                        placeholder="RAW FOOTAGE (OPTIONAL)"
                        name="raw_footage"
                        value={formState.raw_footage ?? ''}
                        onChange={handleInputChange}
                    />
                    <StyledInput
                        type="text"
                        id="additional_notes"
                        placeholder="NOTES (OPTIONAL)"
                        name="additional_notes"
                        value={formState.additional_notes ?? ''}
                        onChange={handleInputChange}
                    />
                </InputGroup>

                <StyledButton type="submit">Update submission</StyledButton>
            </form>
        </Modal>
    );
};

export default EditSubmissionModal;
