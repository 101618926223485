import { BaseLevel, ProfileRecord } from 'common/types/API';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'utils/style/colors';

interface LevelCardProps {
    display: string;
    level?: BaseLevel;
    record?: ProfileRecord;
}

interface StyledLevelCard {
    $levelid: string;
}

const StyledLevelCard = styled.li<StyledLevelCard>`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.25rem 0;
    max-height: 5rem;
    min-height: 5rem;
    width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
    text-shadow:
        0.2rem 0.2rem 0.5rem black,
        0.2rem 0.2rem 0.5rem black,
        0.2rem 0.2rem 0.5rem black,
        0.2rem 0.2rem 0.5rem black;

    background: ${(props) =>
        `url(https://raw.githubusercontent.com/cdc-sys/level-thumbnails/main/thumbs/${props.$levelid}.png)`};
    background-position: center;
    background-repeat: no-repeat;

    &.legacy {
        filter: grayscale(1);
    }
    .levelinfo {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;

        .levelname {
            display: flex;
            margin: 0 2%;
            padding: 0;
            gap: 0;
            flex-direction: column;
            flex-grow: 1;
        }

        span {
            font-weight: 500;
            margin: 0;
            color: ${colors.primary};
            height: fit-content;
            text-transform: uppercase;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            text-align: left;
            &.rank {
                font-size: 3vh;
                width: 12%;
                vertical-align: middle;
                text-align: center;
                @media (max-width: 1000px) {
                    font-size: 2.5vh;

                    min-width: 25%;
                }
            }

            &.name,
            &.moreinfo {
                font-size: 3vh;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }

            &.moreinfo {
                padding: 2%;
            }

            @media (max-width: 1000px) {
                &.name {
                    font-size: 2vh;
                }
                &.moreinfo {
                    display: none;
                }
            }
        }
    }

    a {
        &.youtube {
            padding: 0 2%;
            display: flex;
            align-items: center;
        }
        transition: transform 0.3s ease-in-out;
        &:hover {
            transform: scale(1.05);
        }

        img {
            vertical-align: middle;
            width: 4rem;
            filter: drop-shadow(0.2rem 0.2rem 0.2rem black);
            @media (max-width: 1000px) {
                max-height: 4vh;
            }
        }
    }
`;

function LevelCard(props: LevelCardProps) {
    const level = props.record?.level ?? props.level;

    return (
        <>
            {level ? (
                <StyledLevelCard
                    $levelid={level.level_id}
                    className={level.legacy ? 'legacy' : ''}
                >
                    <div className="levelinfo">
                        <span className="rank">
                            {!level.legacy ? `#${level.position}` : '-'}
                        </span>
                        <Link
                            className="levelname"
                            to={`/list/${level.level_id + (level.two_player ? '/2p' : '')}`}
                        >
                            <span className="name">{level.name}</span>
                            {props.record ? (
                                <span className="points">
                                    {!level.legacy
                                        ? `+${level.points / 10} points`
                                        : 'legacy'}
                                </span>
                            ) : (
                                ''
                            )}
                        </Link>
                        {props.display !== 'completed' ? (
                            <span className="moreinfo">{props.display}</span>
                        ) : (
                            ''
                        )}
                    </div>

                    {props.record ? (
                        <Link
                            className="youtube"
                            to={props.record.video_url}
                            target="_about:blank"
                        >
                            <img src="/assets/youtube.svg" alt="Youtube link" />
                        </Link>
                    ) : (
                        ''
                    )}
                </StyledLevelCard>
            ) : (
                ''
            )}
        </>
    );
}

export default LevelCard;
