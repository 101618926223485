import PropTypes from 'prop-types';
import styled from 'styled-components';

interface YTProps {
    link: string;
}

const getYoutubeIdFromUrl = (url: string) => {
    return (
        url.match(
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/,
        )?.[1] ?? ''
    );
};

const StyledYTEmbed = styled.div`
    width: 90%;
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 0.5rem;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        overflow: hidden;
    }
`;

function YoutubeEmbed(props: YTProps) {
    return (
        <StyledYTEmbed>
            <iframe
                src={`https://www.youtube-nocookie.com/embed/${getYoutubeIdFromUrl(props.link)}`}
                allowFullScreen
                title="Embedded youtube video"
            />
        </StyledYTEmbed>
    );
}

YoutubeEmbed.propTypes = {
    link: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
