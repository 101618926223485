import styled from 'styled-components';
import colors from 'utils/style/colors';

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${colors.backgroundDark};
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;

    @media (min-width: 1000px) {
        width: 40%;
    }

    h2,
    p,
    ul {
        margin-bottom: 1rem;
    }

    h2 {
        font-size: 2.2rem;
        font-weight: 700;
        color: ${colors.primary};
        border-bottom: 2px solid ${colors.primary};
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.1rem;
        line-height: 1.8;
        color: ${colors.primary};
    }

    ul {
        font-size: 1rem;
        line-height: 1.8;
        color: ${colors.primaryLight};
        padding-left: 1.5rem;
        list-style-type: disc;
    }

    li {
        margin-bottom: 0.75rem;
        position: relative;
        color: white;
    }

    span {
        font-weight: 700;
        color: ${colors.primary};
    }
`;

function Guidelines() {
    return (
        <TextContainer>
            <h2>Submission Guidelines</h2>
            <p>
                When submitting your record, please ensure that it complies with
                the following guidelines:
            </p>
            <ul>
                <li>
                    Your recording is a complete playthrough of the level from
                    0-100 without any cuts (if there are cuts in your video,
                    please include an uncut raw footage)
                </li>
                <li>
                    Your completion needs to have clicks. If it doesn&apos;t (or
                    if it only does for a part of the level, and not the entire
                    run), you have to provide a raw footage with clicks. If you
                    don&apos;t, your record will be rejected. Mobile players are
                    NOT exempt from this rule, recorded taps are required.
                </li>
                <li>
                    Your completion must contain at least a second of a previous
                    attempt, including the death. If the completion does not
                    comply, it will be denied and you will be asked to provide
                    raw footage. If the record was achieved on the first attempt
                    it is exempt from this rule.
                </li>
                <li>
                    Achieved the record on the level that is listed on the site
                    (or an approved LDM of it) - please check the level ID
                    before you submit a record
                </li>
                <li>
                    The record has been achieved without using a secret way or a
                    bugged route
                </li>
                <li>
                    Cheat Indicator is required if you are using a mod menu that
                    supports one, like Megahack v8.
                </li>
                <li>
                    End stats (The whole box must appear for at least one frame)
                </li>
                <li>
                    FPS/TPS counter is required only on 2.1 GDPS up to 360
                    fps/tps
                </li>
                <li>
                    Using physics bypass on any level in 2.2 is not allowed, and
                    will get your record rejected
                </li>
                <li>
                    Using the 2.1 GDPS is allowed for levels verified in 2.1, up
                    to 360 FPS/TPS
                </li>
                <li>
                    Raw footage (or a pointercrate record of the level) AND
                    clicks will ALWAYS be required for levels in the top 250.
                </li>
            </ul>
            <h2>Why was my record denied?</h2>
            <p>If your record was denied, please check the following:</p>
            <ul>
                <li>Does the video meet the requirements? (Above)</li>
                <li>Is the level placed on the list? (#pending-changes)</li>
                <li>Was the submission command filled out correctly?</li>
                <li>Was the record submitted with several links?</li>
            </ul>
            <p>
                Note: If you are still unsure of why your record was denied, or
                if the record was wrongfully denied, please make a post in
                #support or DM any list staff on Discord
            </p>
        </TextContainer>
    );
}

export default Guidelines;
