import { useLeaderboardStore } from 'common/stores';
import ValueSwitch from 'components/Layout/ValueSwitch';
import Search from 'components/Others/Search';
import styled from 'styled-components';
import { FaFilter } from 'react-icons/fa';
import { useState } from 'react';
import colors from 'utils/style/colors';
import { LeaderboardSettingsModal } from './LeaderboardSettings';

const StyledLeaderboardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 3rem;

    h1 {
        font-size: 2.5rem;
        margin-left: 5%;
        font-weight: 500;
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        h1 {
            margin: 0;
            text-align: center;
            width: 100%;
        }
    }
`;

const StyledLeaderboardHeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-grow: 1;
    @media (max-width: 1200px) {
        flex-direction: column;
        gap: 5%;
        width: 100%;
    }
`;

const StyledLeaderboardHeaderRight = styled.div`
    padding-right: 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 40%;
    gap: 2.5%;
    flex-grow: 1;

    .filter {
        font-size: 2rem;
        color: ${colors.primaryLight};
        cursor: pointer;
        transition: color 0.25s;
        &:hover {
            color: ${colors.primary};
        }
    }

    @media (max-width: 1200px) {
        padding: 1rem 0;
        min-width: 100%;
    }
`;

function LeaderboardHeader() {
    const { search, setSearch, listType, setListType, setPage } =
        useLeaderboardStore();

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <StyledLeaderboardHeader>
            <StyledLeaderboardHeaderLeft>
                <h1>LEADERBOARD</h1>
                <ValueSwitch
                    options={['classic', 'platformer']}
                    current={listType}
                    set={setListType}
                />
            </StyledLeaderboardHeaderLeft>
            <StyledLeaderboardHeaderRight>
                <Search
                    input={search}
                    setinput={(value: string) => {
                        setPage(1);
                        setSearch(value);
                    }}
                />
                <FaFilter
                    className="filter"
                    onClick={() => setIsModalOpen(true)}
                />
            </StyledLeaderboardHeaderRight>
            <LeaderboardSettingsModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </StyledLeaderboardHeader>
    );
}

export default LeaderboardHeader;
