/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CardWrapper from 'components/Layout/CardWrapper';
import colors from 'utils/style/colors';
import Search from '../Others/Search';
import { usePacks } from 'utils/publicQueries';

import { Loader } from 'components/Others/Loader';
import { Pack } from 'common/types/API';
import ScrollWrapper from 'components/Layout/ScrollWrapper';
import ErrorComponent from 'components/Others/Error';
import { Dropdown, DropdownItem } from 'components/Layout/Dropdown';
import Modal from 'components/Layout/Modal';
import { usePackStore } from 'common/stores';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

interface PackProps {
    pack: Pack;
    color: string;
    search: string;
}

interface PacksProps {
    className?: string;
}

interface PacksModalProps {
    isOpen: boolean;
    onClose: () => void;
    sortType: string;
    setSortType: (value: string) => void;
}

const StyledPackLink = styled.li<{ color: string }>`
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin: 0.15vh;
    padding: 0.5vh;
    white-space: nowrap;
    align-items: center;
    border-radius: 0.25rem;
    font-weight: 400;
    overflow: hidden;
    cursor: pointer;

    flex: 1 1 auto;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${(props) => props.color};
        opacity: 0.5;
        z-index: 0;
        border-radius: 0.25rem;
    }

    p {
        margin: 0 0 0 0.5vw;
        display: block;
        font-size: 2vh;
        z-index: 1;
        text-shadow: 0.1rem 0.1rem 0.2rem black;

        span {
            color: ${colors.primaryLight};
        }
    }

    .level-name {
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding-right: 1rem;

        z-index: 1;
    }

    &:not(.focus):hover:before {
        opacity: 1;
        transition: opacity 0.25s;
    }

    &.focus {
        width: fit-content;
    }

    &.focus:before {
        opacity: 1;
    }
`;

const ListControl = styled.div`
    width: 90%;
    gap: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
        height: 2.5rem;
        transition: transform 0.5s;
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }
`;

const PackLink = React.forwardRef<HTMLLIElement, PackProps>((props, ref) => {
    const { selectPack, selectedPack } = usePackStore();
    const navigate = useNavigate();
    const { data } = usePacks();
    const packs = data ?? [];

    useEffect(() => {
        if (
            props.pack.id === selectedPack &&
            ref &&
            'current' in ref &&
            ref.current
        ) {
            ref.current?.scrollIntoView({
                behavior: 'auto',
                block: 'center',
            });
        }
    }, [props.search]);

    return (
        <StyledPackLink
            ref={ref}
            color={props.color}
            onClick={() => {
                if (selectPack(props.pack.id, packs)) {
                    navigate(`/packs/${props.pack.id}`);
                }
            }}
            className={props.pack.id === selectedPack ? 'focus' : ''}
        >
            <p className="level-name">{props.pack.name}</p>
        </StyledPackLink>
    );
});
PackLink.displayName = 'LevelLink';

const ModalContainer = styled.div`
    width: 40vw;
    min-height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    h1 {
        padding: 0;
        font-size: 1.5rem;
        border: none;
    }
    @media (max-width: 1000px) {
        overflow-y: auto;
        width: 80vw;
        height: 50vh;
    }
`;

const ListSettingsModal: React.FC<PacksModalProps> = ({
    isOpen,
    sortType,
    setSortType,
    onClose,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalContainer>
                <h1>Packs search settings</h1>
                <p>Sort by:</p>
                <Dropdown
                    buttonText={sortType}
                    content={
                        <>
                            <DropdownItem
                                onClick={() => setSortType('Default')}
                            >
                                Default
                            </DropdownItem>
                            <DropdownItem onClick={() => setSortType('Name')}>
                                Name
                            </DropdownItem>
                            <DropdownItem onClick={() => setSortType('Points')}>
                                Points
                            </DropdownItem>
                        </>
                    }
                />
            </ModalContainer>
        </Modal>
    );
};

const Title = styled.h1`
    font-size: 3rem;
    width: 30%;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
`;
const StyledTierHeader = styled.li<{ color: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.color};
    border-radius: 0.25rem;
    width: 100%;
    margin: 0.25rem 0;
    cursor: pointer;
    p {
        font-size: 3vh;
        margin: 0;
        padding-left: 1rem;
        border-bottom: none;
        text-shadow: 0.1rem 0.1rem 0.2rem black;
    }

    .toggle-icon {
        margin: 0.5rem;
        flex-grow: 1;
        vertical-align: middle;

        svg {
            vertical-align: middle;
            width: 1rem;
            height: 1rem;
        }
    }
`;

interface TierHeaderProps {
    tier: { id: string; name: string; color: string };
    isCollapsed: boolean;
    onToggle: () => void;
}

function TierHeader(props: TierHeaderProps) {
    return (
        <StyledTierHeader color={props.tier.color} onClick={props.onToggle}>
            <p>{props.tier.name}</p>
            <span className="toggle-icon">
                {props.isCollapsed ? <FaChevronUp /> : <FaChevronDown />}
            </span>
        </StyledTierHeader>
    );
}

const TierWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 0.5rem 0;
    padding: 0;
    list-style: none;
`;

function PacksList(props: PacksProps) {
    const [search, setSearch] = useState('');
    const [sortType, setSortType] = useState('Default');
    const [isModalOpen, setModalOpen] = useState(false);
    const { pack_id } = useParams();
    const [openedTiers, setOpenedTiers] = useState<{
        [key: string]: boolean;
    }>({});
    const { selectPack, selectedPack } = usePackStore();
    const { data, error, isLoading, isError } = usePacks();
    const tiers = data ?? [];
    const navigate = useNavigate();
    const refMap = useRef<Map<string, React.RefObject<HTMLLIElement>>>(
        new Map(),
    );

    const toggleTier = (tierId: string) => {
        setOpenedTiers((state) => ({
            ...state,
            [tierId]: !state[tierId],
        }));
    };

    function selectDefault() {
        const pack = tiers[0].packs[0];
        if (selectPack(pack.id, tiers)) {
            navigate(`/packs/${pack.id}`, { replace: true });
        }
    }
    useEffect(() => {
        if (isLoading || isError) return;
        if (pack_id == null) {
            selectDefault();
        } else {
            if (pack_id === selectedPack) return;

            let pack;
            for (const tier of tiers) {
                const found_pack = tier.packs.find(
                    (pack) => pack.id === pack_id,
                );
                if (found_pack) {
                    pack = found_pack;
                }
            }
            if (pack) {
                selectPack(pack.id, tiers);
            } else {
                selectDefault();
            }
        }
    }, [isLoading]);

    const filteredTiers = tiers
        .map((tier) => {
            const filteredPacks = tier.packs.filter((pack) =>
                pack.name.toLowerCase().includes(search.toLowerCase()),
            );
            return {
                ...tier,
                packs: filteredPacks,
            };
        })
        .filter((tier) => tier.packs.length > 0);

    const sortedPacksTiers = filteredTiers.map((tier) => {
        const sortedPacks = tier.packs.sort((packA, packB) => {
            if (sortType === 'Name') {
                return packA.name.localeCompare(packB.name);
            } else if (sortType === 'Points') {
                return packB.points - packA.points;
            } else {
                return 0;
            }
        });
        return {
            ...tier,
            packs: sortedPacks,
        };
    });
    const sortedTiers = sortedPacksTiers.sort((tierA, tierB) => {
        if (sortType === 'Points') {
            const averageA =
                tierA.packs.length > 0
                    ? tierA.packs.reduce((sum, pack) => sum + pack.points, 0) /
                      tierA.packs.length
                    : 0;
            const averageB =
                tierB.packs.length > 0
                    ? tierB.packs.reduce((sum, pack) => sum + pack.points, 0) /
                      tierB.packs.length
                    : 0;
            return averageB - averageA;
        } else {
            return 0;
        }
    });

    sortedTiers.forEach((tier) => {
        tier.packs.forEach((pack) => {
            refMap.current.set(
                `${pack.id}-${tier.id}`,
                React.createRef<HTMLLIElement>(),
            );
        });
    });

    return (
        <CardWrapper className={props.className}>
            <Title>Packs</Title>
            <ListControl>
                <Search input={search} setinput={setSearch} />
                <img
                    src="/assets/settings.svg"
                    alt="Packs search settings"
                    onClick={() => setModalOpen(true)}
                />
            </ListControl>

            {isLoading ? (
                <Loader />
            ) : isError ? (
                error instanceof Error ? (
                    <ErrorComponent message={error.message} />
                ) : (
                    ''
                )
            ) : (
                <ScrollWrapper>
                    {sortedTiers.map((tier) => (
                        <React.Fragment key={`tier-${tier.id}`}>
                            <TierHeader
                                tier={tier}
                                isCollapsed={!openedTiers[tier.id]}
                                onToggle={() => toggleTier(tier.id)}
                            />
                            <TierWrapper>
                                {openedTiers[tier.id] &&
                                    tier.packs.map((pack) => {
                                        const ref = refMap.current.get(
                                            `${pack.id}-${tier.id}`,
                                        );
                                        return (
                                            <PackLink
                                                key={`pack-${pack.id}`}
                                                pack={pack}
                                                color={tier.color}
                                                search={search}
                                                ref={ref}
                                            />
                                        );
                                    })}
                            </TierWrapper>
                        </React.Fragment>
                    ))}
                </ScrollWrapper>
            )}
            <ListSettingsModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                sortType={sortType}
                setSortType={setSortType}
            />
        </CardWrapper>
    );
}

export default PacksList;
