import ScrollWrapper from 'components/Layout/ScrollWrapper';
import { Loader } from 'components/Others/Loader';
import { useState } from 'react';
import { useChangelog } from 'utils/publicQueries';
import ErrorComponent from 'components/Others/Error';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { ChangelogEntry } from 'common/types/API';
import { LevelLink } from 'components/Others/LevelDisplay';
import Pagination from 'components/Layout/Pagination';

const StyledChangelogEntry = styled.div`
    width: 100%;
    margin: 0;
    margin-left: 2%;
    text-align: justify;

    h3 {
        color: ${colors.secondary};
        font-weight: bold;
        margin: 0.5rem 0 0 0;
    }

    ul {
        padding-left: 1rem;
        margin: 0;
        margin-right: 5%;
    }
    li {
        margin: 0;
        color: ${colors.primaryLight};
        a,
        span {
            color: ${colors.primary};
        }
    }
`;

function Changelog() {
    const [changelogPage, setChangelogPage] = useState(1);
    const {
        data: changelogData,
        isLoading: isChangelogLoading,
        isError: isChangelogError,
        error: changelogError,
    } = useChangelog(changelogPage);

    interface GroupedEntries {
        date: string;
        entries: ChangelogEntry[];
    }

    const getAboveBelow = (entry: ChangelogEntry) => {
        return (
            <>
                {entry.level_below ? (
                    <>
                        above
                        <LevelLink level={entry.level_below} />
                        {entry.level_above ? 'and ' : ''}
                    </>
                ) : (
                    ''
                )}
                {entry.level_above ? (
                    <>
                        below
                        <LevelLink level={entry.level_above} />
                    </>
                ) : (
                    ''
                )}
            </>
        );
    };

    const getChangelogMessage = (entry: ChangelogEntry) => {
        if ('Placed' in entry.action) {
            return (
                <>
                    <LevelLink level={entry.affected_level} />
                    was placed at{' '}
                    <span>#{entry.action.Placed.new_position}</span>,{' '}
                    {getAboveBelow(entry)}
                </>
            );
        } else if ('Raised' in entry.action) {
            return (
                <>
                    <LevelLink level={entry.affected_level} />
                    was raised from{' '}
                    <span>#{entry.action.Raised.old_position}</span> to{' '}
                    <span>#{entry.action.Raised.new_position}</span>,{' '}
                    {getAboveBelow(entry)}
                </>
            );
        } else if ('Lowered' in entry.action) {
            return (
                <>
                    <LevelLink level={entry.affected_level} />
                    was lowered from
                    <span>#{entry.action.Lowered.old_position}</span> to{' '}
                    <span>#{entry.action.Lowered.new_position}</span>,{' '}
                    {getAboveBelow(entry)}
                </>
            );
        } else if ('Removed' in entry.action) {
            return (
                <>
                    <LevelLink level={entry.affected_level} />
                    was removed from the list
                </>
            );
        } else if ('Swapped' in entry.action) {
            return (
                <>
                    <LevelLink level={entry.affected_level} />
                    and
                    <LevelLink level={entry.action.Swapped.other_level} />
                    have been swapped, with
                    <LevelLink level={entry.action.Swapped.upper_level} />
                    now sitting above at{' '}
                    <span>#{entry.action.Swapped.upper_position}</span>
                </>
            );
        } else if ('MovedToLegacy' in entry.action) {
            return (
                <>
                    <LevelLink level={entry.affected_level} />
                    was moved from {entry.action.MovedToLegacy.old_position} to
                    the legacy list
                </>
            );
        } else if ('MovedFromLegacy' in entry.action) {
            return (
                <>
                    <LevelLink level={entry.affected_level} />
                    was moved from the legacy list to{' '}
                    {entry.action.MovedFromLegacy.new_position},{' '}
                    {getAboveBelow(entry)}
                </>
            );
        }
        return <div>Unknown action</div>;
    };

    const groupEntries = (entries: ChangelogEntry[]) => {
        const groupedEntries = entries.reduce(
            (acc: GroupedEntries[], entry: ChangelogEntry) => {
                const date = entry.created_at.split('T')[0];
                const existingGroup = acc.find((group) => group.date === date);
                if (existingGroup) {
                    existingGroup.entries.push(entry);
                } else {
                    acc.push({ date, entries: [entry] });
                }
                return acc;
            },
            [] as GroupedEntries[],
        );
        return groupedEntries;
    };

    return (
        <>
            {isChangelogLoading ? (
                <Loader />
            ) : !changelogData ? (
                <Loader />
            ) : isChangelogError ? (
                changelogError instanceof Error ? (
                    <ErrorComponent message={changelogError.message} />
                ) : (
                    ''
                )
            ) : (
                <>
                    <ScrollWrapper height="30vh">
                        {groupEntries(changelogData.data).map(
                            (entries: GroupedEntries, index) => (
                                <StyledChangelogEntry key={index}>
                                    <h3>{entries.date}</h3>
                                    <ul>
                                        {entries.entries.map((entry, index) => (
                                            <li key={index}>
                                                {getChangelogMessage(entry)}
                                            </li>
                                        ))}
                                    </ul>
                                </StyledChangelogEntry>
                            ),
                        )}
                    </ScrollWrapper>
                    <Pagination
                        currentPage={changelogPage}
                        totalPages={changelogData.pages}
                        onPageChange={setChangelogPage}
                    />
                </>
            )}
        </>
    );
}

export default Changelog;
