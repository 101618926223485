import { useNotifications } from 'common/stores';
import { Error as ErrorComponent } from 'components';
import StyledButton from 'components/Layout/Button';
import { Dropdown, DropdownItem } from 'components/Layout/Dropdown';
import { Loader } from 'components/Others/Loader';
import Search from 'components/Others/Search';
import { useState } from 'react';
import styled from 'styled-components';
import {
    getAPIKey,
    sendMergeRequest,
    sendNameChangeRequest,
    useUsers,
} from 'utils/authedQueries';
import colors from 'utils/style/colors';
import { Color } from 'components/Others/Notification';
import { StyledInput, StyledTextArea } from 'components/Layout/Input';

const SettingsWrapper = styled.div`
    margin: 1rem 0;
    height: 80vh;
    padding: 0.5rem 5%;
    background-color: ${colors.backgroundDark};
    border-radius: 1.5rem;
    overflow-y: auto;

    h1,
    h2 {
        align-self: flex-start;
        width: 100%;
        box-sizing: border-box;
        overflow-x: clip;
        text-overflow: ellipsis;
        font-weight: 500;
        margin: 0.5rem 0;
    }

    h1 {
        font-size: 2.5rem;
        padding-left: 1rem;
    }

    h2 {
        font-size: 2rem;
    }
`;

const SettingsLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 5%;
    overflow-x: hidden;
    gap: 5%;

    section {
        width: 50%;
    }

    form {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        button {
            margin: 0;
        }

        &.description {
            flex-direction: column;
            gap: 1rem;
            align-items: flex-start;
        }

        @media (max-width: 1000px) {
            width: 80%;
        }
    }

    p {
        margin-bottom: 1rem;
        text-align: justify;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        gap: 2rem;

        section {
            width: 100%;
        }

        form {
            flex-direction: column;
            gap: 1rem;

            &.description {
                textarea {
                    width: 100%;
                }
            }
        }
    }
`;

function Settings() {
    const [selectedUser, setSelectedUser] = useState('');
    const [newName, setNewName] = useState('');
    const [profileDescription, setProfileDescription] = useState('');
    const [search, setSearch] = useState('');
    const { createNotification } = useNotifications();
    const { data, isLoading, isError, error } = useUsers(search);

    const handleMergeRequestSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        sendMergeRequest(selectedUser).then((response) => {
            if (!response.error) {
                createNotification(
                    Color.success,
                    'Merge request submitted successfully',
                );
            } else {
                createNotification(Color.error, response.data.message);
            }
        });
        setSelectedUser('');
    };

    const handleNameRequestSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        sendNameChangeRequest(newName).then((response) => {
            if (!response.error) {
                createNotification(
                    Color.success,
                    'Name change request submitted/edited successfully',
                );
            } else {
                createNotification(Color.error, response.data.message);
            }
        });

        setSelectedUser('');
    };

    const handleAPIKey = (event: React.FormEvent): void => {
        event.preventDefault();
        getAPIKey().then((response) => {
            if (!response.error) {
                navigator.clipboard.writeText(response.data['api-key']);
                createNotification(
                    Color.success,
                    'API Key copied to clipboard',
                );
            } else {
                createNotification(Color.error, response.data.message);
            }
        });
        setSelectedUser('');
    };

    const handleProfileDescription = (event: React.FormEvent): void => {
        event.preventDefault();
        console.log(`TODO edit profile desc (${profileDescription})`);
    };

    return (
        <SettingsWrapper>
            <h1>Settings</h1>
            <SettingsLayout>
                <section>
                    <h2>Profile settings</h2>
                    <form
                        className="description"
                        onSubmit={handleProfileDescription}
                    >
                        <StyledTextArea
                            placeholder="PROFILE DESCRIPTION"
                            name="edit_profile"
                            rows={5}
                            cols={50}
                            value={profileDescription}
                            onChange={(event) => {
                                setProfileDescription(event.target.value);
                            }}
                            required
                        />
                        <StyledButton type="submit">
                            Edit Profile Description
                        </StyledButton>
                    </form>
                    <h2>Developer settings</h2>
                    <p>
                        The AREDL API is available{' '}
                        <strong>
                            <a
                                href="https://api.aredl.net/api"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here
                            </a>
                        </strong>
                        , and the corresponding documentation can be found{' '}
                        <strong>
                            <a
                                href="https://api.aredl.net/docs/index.html"
                                rel="noreferrer"
                                target="_blank"
                            >
                                here
                            </a>
                        </strong>
                        . To use authenticated endpoints, you must provide your
                        API Key in the header, as Authorization or as
                        &quot;api-key&quot;.
                        <br />
                        <StyledButton onClick={handleAPIKey}>
                            Copy API Key
                        </StyledButton>
                    </p>
                </section>
                <section>
                    <h2>Merge request</h2>
                    <p>
                        You can request here for your account to be merged with
                        a placeholder account (One that was created
                        automatically after the data migration from the previous
                        website, or because you created/verified/published a
                        level on the list.)
                    </p>
                    <form onSubmit={handleMergeRequestSubmit}>
                        <Dropdown
                            buttonText={`${
                                selectedUser !== ''
                                    ? `${selectedUser}`
                                    : 'Select User'
                            }`}
                            content={
                                <>
                                    <Search
                                        input={search}
                                        setinput={setSearch}
                                    />
                                    {isLoading ? (
                                        <Loader />
                                    ) : !data ? (
                                        <Loader />
                                    ) : isError ? (
                                        error instanceof Error ? (
                                            <ErrorComponent
                                                message={error.message}
                                            />
                                        ) : (
                                            ''
                                        )
                                    ) : data.length > 0 ? (
                                        data.map((current_user) => (
                                            <DropdownItem
                                                key={current_user.id}
                                                onClick={() =>
                                                    setSelectedUser(
                                                        current_user.global_name,
                                                    )
                                                }
                                            >
                                                {`${current_user?.global_name}`}
                                            </DropdownItem>
                                        ))
                                    ) : (
                                        'No results'
                                    )}
                                </>
                            }
                        />

                        <StyledButton type="submit">
                            Submit Merge Request
                        </StyledButton>
                    </form>
                    <h2>Name change request</h2>
                    <p>
                        You can request here for your global name to be changed
                    </p>
                    <form onSubmit={handleNameRequestSubmit}>
                        <StyledInput
                            type="text"
                            placeholder="NEW NAME"
                            name="new_name"
                            value={newName}
                            onChange={(event) => {
                                setNewName(event.target.value);
                            }}
                            required
                        />
                        <StyledButton type="submit">
                            Submit Name Change Request
                        </StyledButton>
                    </form>
                </section>
            </SettingsLayout>
        </SettingsWrapper>
    );
}

export default Settings;
