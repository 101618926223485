import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import colors from 'utils/style/colors';
import { createPortal } from 'react-dom';
import { FaXmark } from 'react-icons/fa6';

function createContainer() {
    const portalId = 'notifyContainer';
    let element = document.getElementById(portalId);

    if (element) {
        return element;
    }

    element = document.createElement('div');
    element.setAttribute('id', portalId);

    element.style.position = 'fixed';
    element.style.bottom = '2rem';
    element.style.right = '2rem';
    element.style.zIndex = '100';

    document.body.appendChild(element);

    return element;
}

const container = createContainer();
const timeToDelete = 300;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
	opacity: 0%;
  }
  to {
    transform: translateY(0%);
	opacity: 100%;
  }
`;

const XIcon = styled(FaXmark)`
    height: 100%;
    margin: 0;
    margin-right: 1rem;
    padding: 0.15rem 0.3rem;
    border-radius: 0.25rem;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
`;

const StyledNotification = styled.div`
    max-width: 20rem;
    max-height: 5rem;
    overflow: hidden;
    padding: 1rem 4rem 1rem 1rem;
    z-index: 101;
    font-weight: 500;
    position: relative;
    color: ${colors.primary};
    margin-bottom: 1rem;
    animation: ${slideIn} 0.3s ease-in-out;
    transition:
        transform 0.3s ease-out,
        opacity 0.3s ease-out;

    &.info {
        background-color: #2196f3;
    }

    &.success {
        background-color: #4caf50;
    }

    &.warning {
        background-color: #ff9800;
    }

    &.error {
        background-color: #f44336;
    }

    .closeButton {
        background: transparent;
        vertical-align: middle;
        position: absolute;
        border: none;
        cursor: pointer;
    }

    &.slideOut {
        transform: translateY(-100%);
        opacity: 0%;
        flex: 0;
    }
`;

const Color = {
    info: 'info',
    success: 'success',
    warning: 'warning',
    error: 'error',
} as const;

interface NotificationProps {
    color?: keyof typeof Color;
    onDelete: () => void;
    autoClose?: boolean;
    children?: ReactNode;
}

const Notification: React.FC<NotificationProps> = ({
    color = Color.info,
    onDelete,
    autoClose = true,
    children,
}) => {
    const [isClosing, setIsClosing] = React.useState(false);

    React.useEffect(() => {
        if (isClosing) {
            const timeoutId = setTimeout(onDelete, timeToDelete);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isClosing, onDelete]);

    React.useEffect(() => {
        if (autoClose) {
            const timeoutId = setTimeout(() => setIsClosing(true), 10000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [autoClose]);

    return createPortal(
        <StyledNotification
            className={`${color} ${isClosing ? 'slideOut' : ''}`}
        >
            {children}
            <button onClick={() => setIsClosing(true)} className="closeButton">
                <IconWrapper>
                    <XIcon color="white" id="x-icon" height="1rem" />
                </IconWrapper>
            </button>
        </StyledNotification>,
        container,
    );
};

Notification.propTypes = {
    color: PropTypes.oneOf(Object.keys(Color) as (keyof typeof Color)[]),
    children: PropTypes.node,
};

export { Notification, Color };
