import React, { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from 'common/stores/auth';

interface ProtectedRouteProps {
    redirectPath?: string;
    children?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    redirectPath = '/',
    children,
}) => {
    const { isAuthenticated } = useAuth();
    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace />;
    }
    return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
