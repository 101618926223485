import { ProfilePack } from 'common/types/API';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'utils/style/colors';

interface PackCardProps {
    display: string;
    pack: ProfilePack;
}

interface StyledPackCard {
    $packcolor: string;
}

const StyledPackCard = styled.li<StyledPackCard>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 0;
    margin: 0;
    max-height: 5rem;
    min-height: 5rem;
    border-radius: 0.5rem;
    width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
    text-shadow:
        0.2rem 0.2rem 0.5rem black,
        0.2rem 0.2rem 0.5rem black;

    background: ${(props) => props.$packcolor};

    .packname {
        display: flex;
        flex-grow: 1;
        margin: 0 5%;
        padding: 0;
        gap: 0;
        flex-direction: column;
    }

    .tiername {
        margin-right: 1rem;
        padding: 0.5rem;
        font-size: 2rem;
        width: fit-content;

        @media (max-width: 1000px) {
            display: none;
        }
    }

    span {
        font-weight: 500;
        margin: 0;
        color: ${colors.primary};
        height: fit-content;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        text-align: left;

        &.name {
            font-size: 2rem;
        }

        @media (max-width: 1000px) {
            overflow-x: visible;
            &.name {
                font-size: 1.3rem;
            }
        }
    }

    a {
        transition: transform 0.3s ease-in-out;
        &:hover {
            transform: scale(1.05);
        }
    }
`;

function PackCard(props: PackCardProps) {
    return (
        <>
            {props.pack ? (
                <StyledPackCard $packcolor={props.pack.tier.color}>
                    <Link className="packname" to={`/packs/${props.pack.id}`}>
                        <span className="name">{props.pack.name}</span>
                        <span className="points">
                            {`+${props.pack.points ? props.pack?.points / 10 : '0'} points`}
                        </span>
                    </Link>
                    <span className="tiername">{props.pack.tier.name}</span>
                </StyledPackCard>
            ) : (
                ''
            )}
        </>
    );
}

export default PackCard;
