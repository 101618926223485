import { createGlobalStyle } from 'styled-components';
import colors from './colors';

interface BackgroundProps {
    background: string;
    bgopacity: string;
    grayscale: string;
}

const GlobalStyle = createGlobalStyle<BackgroundProps>`

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	svg {
		box-sizing: content-box;
	}

	body {
		color: ${colors.primaryLight};
		margin: 1rem 5vw;
		background-color: ${colors.backgroundLight}; 
    }

	body::before {
		content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(${(props) => props.background});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: ${(props) => props.bgopacity};
		filter: grayscale(${(props) => props.grayscale});
        z-index: -1;
	}
	main {
		margin: 1rem 0;
	}

	h1, h2, h3 {
		color: ${colors.primary}
	}

	a, a:visited {
		color: inherit;
		text-decoration: none;
	}

	span, strong {
		color: ${colors.secondary}
	}
	div {
        font-family: Poppins;
		box-sizing: border-box;
    }

`;

export default GlobalStyle;
