import { useQuery } from 'react-query';
import backend from 'common/backend';
import { Submission, User } from 'common/types/API';
import { SubmissionData } from 'components/Submissions/Submit';
import { useAuth } from 'common/stores';

export function useAuthSubmissions() {
    const { isAuthenticated, user } = useAuth();

    return useQuery({
        queryKey: ['submissions', user?.id ?? ''],
        queryFn: async (): Promise<Submission[]> => {
            const response = await backend.send('/aredl/me/submissions', 'GET');
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: isAuthenticated,
    });
}

export function useUsers(name_filter: string) {
    const { isAuthenticated } = useAuth();

    return useQuery({
        queryKey: ['users', name_filter],
        queryFn: async (): Promise<User[]> => {
            const response = await backend.send('/users', 'GET', {
                name_filter: name_filter,
                per_page: -1,
            });
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: isAuthenticated,
    });
}

export async function putSubmission(data: SubmissionData) {
    return await backend.send('/aredl/me/submissions', 'PUT', data);
}

export async function sendMergeRequest(name: string) {
    return await backend.send('/merge-requests', 'POST', {
        placeholder_name: name,
    });
}

export async function sendNameChangeRequest(name: string) {
    return await backend.send('/name-change-requests', 'PUT', {
        new_name: name,
    });
}

export async function getAPIKey() {
    return await backend.send('/me/api-key', 'GET');
}
