// index.tsx (main entry point)
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
    Home,
    List,
    Leaderboard,
    Profile,
    Clan,
    Packs,
    Submissions,
    Roulette,
    Settings,
} from 'views';

import { Header, Error } from 'components';
import GlobalStyleWrapper from 'utils/style/GlobalStyleWrapper';
import ProtectedRoute from 'views/ProtectedRoute';
import { Notification } from 'components/Others/Notification';
import { useNotifications, useCountryStore } from 'common/stores';
import AuthRefresher from 'components/Others/AuthRefresher';

const queryClient = new QueryClient();

const App = () => {
    const { notifications, deleteNotification } = useNotifications();
    const { fetchCountries } = useCountryStore();

    useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <React.StrictMode>
            <AuthRefresher />
            <GlobalStyleWrapper />
            <Router>
                <QueryClientProvider client={queryClient}>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                            path="/list/:level_id?/:is_2p?"
                            element={<List />}
                        />
                        <Route
                            path="/leaderboard/:page?"
                            element={<Leaderboard />}
                        />
                        <Route path="/profile/:userid" element={<Profile />} />
                        <Route path="/clan/:clanname" element={<Clan />} />
                        <Route path="/packs/:pack_id?" element={<Packs />} />
                        <Route path="/roulette" element={<Roulette />} />
                        <Route element={<ProtectedRoute />}>
                            <Route
                                path="/submissions"
                                element={<Submissions />}
                            />
                            <Route path="/settings" element={<Settings />} />
                        </Route>
                        <Route
                            path="*"
                            element={
                                <Error
                                    code="404"
                                    message="This page does not exist"
                                />
                            }
                        />
                    </Routes>
                    {notifications.map(({ id, color, text }) => (
                        <Notification
                            key={id}
                            color={color}
                            onDelete={() => deleteNotification(id)}
                        >
                            {text}
                        </Notification>
                    ))}
                </QueryClientProvider>
            </Router>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(<App />);
