/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import Modal from 'components/Layout/Modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface InfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    infoType: string;
}

const ModalContainer = styled.div`
    width: 30vw;
    height: 30vh;
    overflow-y: hidden;
    overflow-x: hidden;
    text-align: justify;
    font-weight: 400;
    text-transform: none;
    outline: none;

    &:focus {
        outline: none;
    }

    h1 {
        text-align: left;
        padding: 0;
        font-size: 1.5rem;
        border: none;
    }

    @media (max-width: 1000px) {
        width: 80vw;
        height: 40vh;
        overflow-y: auto;
    }
`;

const PartnerModal: React.FC<InfoModalProps> = ({
    isOpen,
    infoType,
    onClose,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen && modalRef.current) {
            modalRef.current.focus();
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalContainer ref={modalRef} tabIndex={-1}>
                {infoType === 'IDL' ? (
                    <>
                        <h1>Insane Demon List</h1>
                        <p>
                            The IDL (Insane Demon List) is a GD List that aims
                            to rank the top 150 Insane Demons by difficulty.
                        </p>
                        <p>
                            You can find more information about them on{' '}
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://insanedemonlist.com/"
                                >
                                    their website
                                </Link>
                            </strong>
                            !
                        </p>
                    </>
                ) : infoType === 'UDL' ? (
                    <>
                        <h1>Unrated Demons List</h1>
                        <p>
                            The UDL (Unrated Demons List) is a list that is
                            dedicated to rank the top 150 unrated demons in
                            Geometry Dash by difficulty.
                        </p>
                        <p>
                            You can find more information about them on{' '}
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://udl.pages.dev/"
                                >
                                    their website
                                </Link>
                            </strong>{' '}
                            or join their{' '}
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://discord.gg/Uj8m4rr8WA"
                                >
                                    Discord server
                                </Link>
                            </strong>
                            !
                        </p>
                    </>
                ) : infoType === 'EDEL' ? (
                    <>
                        <h1>Extreme Demon Enjoyments List</h1>
                        <p>
                            The EDEL (Extreme Demon Enjoyments List) is an
                            Extreme Demon resource with over 17,000 enjoyment
                            ratings averaged out to help players find the most
                            fun extremes.
                        </p>
                        <p>
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://docs.google.com/spreadsheets/d/1-2-n2aU__rQya_IESevHjEU0f1xbegKUZEslk7fV38Q/"
                                >
                                    Their spreadsheet
                                </Link>
                            </strong>{' '}
                            has more information and an invite to their Discord
                            server, where you can submit your own enjoyments!
                        </p>
                    </>
                ) : infoType === 'EDEL_PENDING' ? (
                    <>
                        <h1>Unreliable Rating</h1>
                        <p>
                            This level is currently low on enjoyments, you can
                            help increase its accuracy by beating it and
                            submitting your enjoyment to the{' '}
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://discord.gg/pdeZY2FU3U"
                                >
                                    EDEL
                                </Link>
                            </strong>{' '}
                            discord!
                        </p>
                    </>
                ) : infoType === 'SFH' ? (
                    <>
                        <h1>Song File Hub</h1>
                        <p>
                            Song File Hub (SFH) is a GD website that lets you
                            quickly search, find, and download NONGs or mashups
                            for any GD level.
                        </p>
                        <p>
                            You can find more information about them on{' '}
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://songfilehub.com/home"
                                >
                                    their website
                                </Link>
                            </strong>
                            !
                        </p>
                    </>
                ) : infoType === 'PEMONLIST' ? (
                    <>
                        <h1>Pemonlist</h1>
                        <p>
                            The Pemonlist (Platformer list) is a list that
                            offers the placements of all rated platformer demons
                            up to 200 levels plus level packs to go along with
                            them.
                        </p>
                        <p>
                            You can find more information about them on{' '}
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://pemonlist.com/"
                                >
                                    their website
                                </Link>
                            </strong>
                            !
                        </p>
                    </>
                ) : infoType === 'GDDL' ? (
                    <>
                        <h1>Geometry Dash Demon Ladder</h1>
                        <p>
                           The Geometry Dash Demon Ladder is created to provide a tier-based ranking system
                           that is aimed to give a proper chart of demon levels to complete over time for efficient improvement.
                        </p>
                        <p>
                            You can find more information about them on{' '}
                            <strong>
                                <Link
                                    target="_blank"
                                    to="https://gdladder.com"
                                >
                                    their website
                                </Link>
                            </strong>
                            !
                        </p>
                    </>
                ) : (
                    ''
                )}
            </ModalContainer>
        </Modal>
    );
};

export default PartnerModal;
