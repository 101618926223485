import { FaSearch } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import styled from 'styled-components';
import { Dispatch, SetStateAction } from 'react';
import colors from 'utils/style/colors';
import { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

interface SProps {
    input: string;
    setinput: Dispatch<SetStateAction<string>> | ((value: string) => void);
}

const InputWrapper = styled.div`
    display: flex;
    background-color: ${colors.backgroundSemiLight};
    padding: 1rem 0 1rem 1rem;
    border-radius: 1rem;
    width: 100%;
    gap: 1rem;
    align-items: center;

    input {
        width: 100%;
        background-color: inherit;
        color: ${colors.primaryLight};
        box-sizing: border-box;
        border: none;
        outline: none;
        font-weight: 500;

        &:focus {
            outline: none;
        }
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
`;

const SearchIcon = styled(FaSearch)`
    height: 100%;
`;

const XIcon = styled(FaXmark)`
    height: 100%;
    margin: 0;
    margin-right: 1rem;
    transition: background-color 0.5s;
    padding: 0.15rem 0.3rem;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
        color: ${colors.primary};
        background-color: ${colors.primaryLight};
    }
`;

function Search(props: SProps) {
    const [localInput, setLocalInput] = useState(props.input);

    const debouncedSetInput = useCallback(
        debounce((value: string) => props.setinput(value), 500),
        [props.setinput],
    );

    useEffect(() => {
        debouncedSetInput(localInput);
    }, [localInput]);

    return (
        <InputWrapper>
            <IconWrapper>
                <SearchIcon id="search-icon" />
            </IconWrapper>
            <input
                placeholder="SEARCH"
                value={localInput}
                onChange={(event) => {
                    setLocalInput(event.target.value);
                }}
            />
            <IconWrapper>
                {localInput !== '' ? (
                    <XIcon id="x-icon" onClick={() => setLocalInput('')} />
                ) : (
                    ''
                )}
            </IconWrapper>
        </InputWrapper>
    );
}

export default Search;
