import { Submission } from 'common/types/API';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import EditSubmissionModal from './EditSubmissionModal';
import { putSubmission } from 'utils/authedQueries';
import { SubmissionData } from './Submit';
import { Color } from 'components/Others/Notification';
import { useNotifications } from 'common/stores';
import { useQueryClient } from 'react-query';

interface SubmissionCardProps {
    submission: Submission;
}

interface StyledSubmissionCard {
    $levelid: string;
}

const StyledSubmissionCard = styled.li<StyledSubmissionCard>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    width: 100%;
    height: 4rem;
    overflow-x: clip;
    text-overflow: ellipsis;

    background: ${(props) =>
        `url(https://raw.githubusercontent.com/cdc-sys/level-thumbnails/main/thumbs/${props.$levelid}.png)`};
    background-position: center;
    background-repeat: no-repeat;

    text-shadow:
        0.2rem 0.2rem 0.5rem black,
        0.2rem 0.2rem 0.5rem black,
        0.2rem 0.2rem 0.5rem black,
        0.2rem 0.2rem 0.5rem black;

    &.legacy {
        filter: grayscale(1);
    }
    .levelinfo {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;

        .levelname {
            display: flex;
            width: 50%;
            margin: 0 5%;
            padding: 0;
            gap: 0;
            flex-direction: column;

            @media (max-width: 1000px) {
                width: 80%;
            }
        }

        span {
            font-weight: 500;
            margin: 0;
            color: ${colors.primary};
            height: fit-content;
            text-transform: uppercase;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            text-align: left;

            &.name {
                font-size: 2rem;
            }

            &.status {
                font-size: 1.25rem;
                padding: 0.15rem 0.3rem;
                background-color: ${colors.pendingTransparent};
                border: ${colors.pending} solid 0.1rem;
                border-radius: 0.5rem;

                box-shadow:
                    0.2rem 0.2rem 0.5rem black,
                    0.2rem 0.2rem 0.5rem black;

                &.rejected {
                    background-color: ${colors.cancelTransparent};
                    border: ${colors.cancel} solid 0.1rem;
                }
            }

            @media (max-width: 1000px) {
                overflow-x: visible;
                &.name {
                    font-size: 1.3rem;
                }
            }
        }
    }

    a,
    .edit {
        transition: transform 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }

    .edit {
        width: 10%;
        display: flex;
        align-items: center;

        img {
            vertical-align: middle;
            width: 3rem;
            filter: drop-shadow(0.2rem 0.2rem 0.2rem black);
        }
    }
`;

function SubmissionCard(props: SubmissionCardProps) {
    const rejected = props.submission.rejected;

    const { createNotification } = useNotifications();
    const [isSubmissionModalOpen, setSubmissionModalOpen] =
        useState<boolean>(false);

    const queryClient = useQueryClient();

    const handleOpenModal = () => {
        setSubmissionModalOpen(true);
    };

    const handleCloseModal = () => {
        setSubmissionModalOpen(false);
    };

    const handleFormSubmit = (data: SubmissionData): void => {
        handleCloseModal();
        putSubmission(data).then((response) => {
            if (!response.error) {
                createNotification(
                    Color.success,
                    'Record updated successfully',
                );
                queryClient.invalidateQueries('submissions');
            } else {
                createNotification(Color.error, response.data.message);
            }
        });
    };

    return (
        <>
            {props.submission ? (
                <>
                    <StyledSubmissionCard
                        $levelid={props.submission.level.level_id}
                        className={
                            props.submission.level.legacy ? 'legacy' : ''
                        }
                    >
                        <div className="levelinfo">
                            <div className="levelname">
                                <Link
                                    to={`/list/${props.submission.level.level_id + (props.submission.level.two_player ? '/2p' : '')}`}
                                >
                                    {' '}
                                    <span className="name">
                                        {props.submission.level.name}
                                    </span>
                                </Link>

                                <span className="update">
                                    Last update: {props.submission.updated}
                                </span>
                            </div>
                            <span
                                className={`status${rejected ? ' rejected' : ''}`}
                            >
                                Status: {rejected ? 'Rejected' : 'Pending'}
                            </span>
                        </div>

                        <div className="edit" onClick={handleOpenModal}>
                            <img src="/assets/settings.svg" alt="Edit record" />
                        </div>
                    </StyledSubmissionCard>
                    <EditSubmissionModal
                        isOpen={isSubmissionModalOpen}
                        onSubmit={handleFormSubmit}
                        onClose={handleCloseModal}
                        submission={props.submission}
                    />
                </>
            ) : (
                ''
            )}
        </>
    );
}

export default SubmissionCard;
