import ScrollWrapper from 'components/Layout/ScrollWrapper';
import { Error as ErrorComponent } from 'components';
import { Loader } from 'components/Others/Loader';
import { useAuthSubmissions } from 'utils/authedQueries';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { Submission } from 'common/types/API';
import SubmissionCard from './SubmissionCard';

const SubmissionsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.backgroundDark};
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    text-transform: uppercase;
`;

function SubmissionsList() {
    const { data, isLoading, isError, error } = useAuthSubmissions();
    return (
        <SubmissionsListContainer>
            <h1>Previous submissions</h1>
            <ScrollWrapper height="50vh">
                {isLoading ? (
                    <Loader />
                ) : !data ? (
                    <Loader />
                ) : isError ? (
                    error instanceof Error ? (
                        <ErrorComponent message={error.message} />
                    ) : (
                        ''
                    )
                ) : (
                    <>
                        {data.length !== 0 ? (
                            data.map((submission: Submission) => (
                                <SubmissionCard
                                    key={submission.id}
                                    submission={submission}
                                />
                            ))
                        ) : (
                            <p>You haven&apos;t submitted a record yet</p>
                        )}
                    </>
                )}
            </ScrollWrapper>
        </SubmissionsListContainer>
    );
}

export default SubmissionsList;
