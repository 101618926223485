import styled from 'styled-components';
import colors from 'utils/style/colors';

const StyledInput = styled.input`
    flex: 1;
    font-size: 1rem;
    border: none;
    padding: 1.15rem;
    background-color: ${colors.backgroundSemiLight};
    border-radius: 0.5rem;
    box-shadow: ${colors.backgroundDark} 0px 8px 24px;
    color: ${colors.primary};

    &:focus {
        outline: none;
    }

    @media (min-width: 1000px) {
        max-width: calc(33% - 2.3rem);
    }
`;

const StyledTextArea = styled.textarea`
    flex: 1;
    font-size: 1rem;
    border: none;
    padding: 1.15rem;
    background-color: ${colors.backgroundSemiLight};
    border-radius: 0.5rem;
    box-shadow: ${colors.backgroundDark} 0px 8px 24px;
    color: ${colors.primary};
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    resize: none;

    &:focus {
        outline: none;
    }
`;

export { StyledInput, StyledTextArea };
