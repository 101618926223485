import { useNavigate } from 'react-router';
import styled from 'styled-components';
import colors from 'utils/style/colors';

const ReturnButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 2rem;
    color: ${colors.primaryLight};
    align-items: center;
    gap: 0.5rem;
    transition: color 0.5s;
    cursor: pointer;

    img {
        filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(81%)
            hue-rotate(227deg) brightness(98%) contrast(89%);
        height: 2rem;
        align-self: center;
        transition: filter 0.5s;
    }

    &:hover {
        color: ${colors.primary};
        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
                saturate(7455%) hue-rotate(48deg) brightness(100%)
                contrast(100%);
        }
    }
`;

function ReturnButton() {
    const navigate = useNavigate();
    return (
        <ReturnButtonWrapper onClick={() => navigate(-1)}>
            <img src="/assets/return.svg" alt="Return icon" />
            RETURN
        </ReturnButtonWrapper>
    );
}

export default ReturnButton;
