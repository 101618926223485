import { useLeaderboardStore } from 'common/stores';
import { useQuery } from 'react-query';
import {
    IDLLevel,
    BaseLevel,
    LeaderboardResponse,
    Profile,
    PackTier,
    ResolvedLevel,
    HistoryEntry,
    BaseUser,
    LevelRecord,
    LevelPack,
    RoleResolved,
} from 'common/types/API';
import useProfileStore from 'common/stores/profile';
import backend from 'common/backend';
import { ChangelogResponse } from 'common/types/API/changelog';

export function useLevels(listType: string) {
    return useQuery(
        listType === 'classic' ? 'list_levels' : 'list_plevels',
        async (): Promise<BaseLevel[]> => {
            const response = await backend.send(
                `/${listType === 'classic' ? 'aredl' : 'pdl'}/levels`,
                'GET',
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
    );
}

export function useIDL() {
    return useQuery('list_idl', async (): Promise<IDLLevel[]> => {
        const response = await fetch('https://insanedemonlist.com/api/levels');
        if (!response.ok) {
            throw new Error('IDL failed to load');
        }
        return response.json();
    });
}

export function useEditors() {
    return useQuery('editors', async (): Promise<RoleResolved[]> => {
        const response = await backend.send('/users/names', 'GET');
        if (response.error) {
            throw new Error(response.data.message);
        }
        return response.data;
    });
}

export function useBaseLevel(listType: string, id: string) {
    return useQuery({
        queryKey: [
            listType === 'classic' ? 'list_levelData' : 'list_plevelData',
            id,
            'base',
        ],
        queryFn: async (): Promise<ResolvedLevel> => {
            const levelURL = `/${listType === 'classic' ? 'aredl' : 'pdl'}/levels/${id}`;
            const response = await backend.send(levelURL, 'GET');
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: id != '',
    });
}

export function useLevelCreators(listType: string, id: string) {
    return useQuery({
        queryKey: [
            listType === 'classic' ? 'list_levelData' : 'list_plevelData',
            id,
            'creators',
        ],
        queryFn: async (): Promise<BaseUser[]> => {
            const response = await backend.send(
                `/${listType === 'classic' ? 'aredl' : 'pdl'}/levels/${id}/creators`,
                'GET',
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: id != '',
    });
}

export function useLevelRecords(listType: string, id: string) {
    return useQuery({
        queryKey: [
            listType === 'classic' ? 'list_levelData' : 'list_plevelData',
            id,
            'records',
        ],
        queryFn: async (): Promise<LevelRecord[]> => {
            const response = await backend.send(
                `/${listType === 'classic' ? 'aredl' : 'pdl'}/levels/${id}/records`,
                'GET',
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: id != '',
    });
}

export function useLevelPacks(listType: string, id: string) {
    return useQuery({
        queryKey: [
            listType === 'classic' ? 'list_levelData' : 'list_plevelData',
            id,
            'packs',
        ],
        queryFn: async (): Promise<LevelPack[]> => {
            const response = await backend.send(
                `/${listType === 'classic' ? 'aredl' : 'pdl'}/levels/${id}/packs`,
                'GET',
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: id != '',
    });
}

export function useLevelHistory(listType: string, id: string) {
    return useQuery({
        queryKey: [
            listType === 'classic' ? 'list_levelData' : 'list_plevelData',
            id,
            'history',
        ],
        queryFn: async (): Promise<HistoryEntry[]> => {
            const response = await backend.send(
                `/${listType === 'classic' ? 'aredl' : 'pdl'}/levels/${id}/history`,
                'GET',
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: id != '',
    });
}

export function useLeaderboard() {
    const { page, listType, search, sort, country } = useLeaderboardStore();

    const sortType = new Map([
        ['total points', 'TotalPoints'],
        ['level points only', 'RawPoints'],
        ['extremes count', 'ExtremeCount'],
    ]);

    const queryParams = {
        name_filter: search != '' ? `%${search}%` : undefined,
        per_page: 20,
        page: page,
        order: sortType.get(sort) ?? 'TotalPoints',
        country_filter: country === '0' ? undefined : country,
    };

    return useQuery(
        ['leaderboard', listType, sort, country, page, search],
        async (): Promise<LeaderboardResponse> => {
            const response = await backend.send(
                `/${listType === 'classic' ? 'aredl' : 'pdl'}/leaderboard`,
                'GET',
                queryParams,
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
    );
}

export function useChangelog(page: number) {
    const queryParams = {
        page: page,
        per_page: 20,
    };

    return useQuery(
        ['changelog', page],
        async (): Promise<ChangelogResponse> => {
            const response = await backend.send(
                `/aredl/changelog`,
                'GET',
                queryParams,
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
    );
}

export function useProfile() {
    const { selectedUser, listType } = useProfileStore();

    return useQuery({
        queryKey: ['profile', selectedUser, listType],
        queryFn: async (): Promise<Profile> => {
            const response = await backend.send(
                `/${listType === 'classic' ? 'aredl' : 'pdl'}/profile/${selectedUser}`,
                'GET',
            );
            if (response.error) {
                throw new Error(response.data.message);
            }
            return response.data;
        },
        enabled: selectedUser != '',
    });
}

export function usePacks() {
    return useQuery('packs_list', async (): Promise<PackTier[]> => {
        const response = await backend.send('/aredl/pack-tiers', 'GET');
        if (response.error) {
            throw new Error(response.data.message);
        }
        return response.data;
    });
}
