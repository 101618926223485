import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import StyledButton from './Button';
import ReactDOM from 'react-dom';
import ScrollWrapper from './ScrollWrapper';
interface ModalProps {
    isOpen: boolean;
    onClose?: () => void;
    children: React.ReactNode;
}

const CloseWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    justify-content: flex-end;

    button {
        margin: 0;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 90%;
    padding: 2rem;
    width: fit-content;
    height: fit-content;
    overflow-x: hidden;
    border: 0;
    color: ${colors.primary};
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
    background: ${colors.modalBackground};
    margin: auto;
    &::backdrop {
        background: hsl(0 0% 0% / 80%);
    }
`;
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    const [isModalOpen, setModalOpen] = useState(isOpen);
    const modalRef = useRef<HTMLDialogElement | null>(null);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [isModalOpen]);

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
        setModalOpen(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === 'Escape') {
            handleCloseModal();
        }
    };

    return ReactDOM.createPortal(
        isModalOpen ? (
            <StyledDialog ref={modalRef} onKeyDown={handleKeyDown}>
                <ScrollWrapper>
                    {children}
                    <CloseWrapper>
                        <StyledButton $cancel={true} onClick={handleCloseModal}>
                            Close
                        </StyledButton>
                    </CloseWrapper>
                </ScrollWrapper>
            </StyledDialog>
        ) : null,

        document.body,
    );
};

export default Modal;
