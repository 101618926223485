import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { useNotifications, useAuth } from 'common/stores';
import { Link } from 'react-router-dom';
const StyledMenu = styled.div`
    display: none;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
    cursor: pointer;

    span {
        height: 0.4rem;
        width: 100%;
        background-color: ${colors.primaryLight};
        border-radius: 0.2rem;
    }

    @media (max-width: 1000px) {
        display: flex;
    }
`;

const StyledNav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 1rem;

    color: ${colors.primaryLight};
    font-weight: 600;
    text-transform: uppercase;

    .active {
        background-color: ${colors.secondary};
        color: ${colors.primary};
    }

    ul {
        display: flex;
    }

    ul li {
        list-style: none;
    }

    ul li a {
        transition: background-color 0.25s;
        display: block;
        text-decoration: none;
        padding: 0.5rem 1rem;
        margin: 0 0.5rem;
        border-radius: 0.5rem;
    }

    ul li a:not(.active):hover {
        background-color: ${colors.primaryLight};
        color: ${colors.primary};
    }

    .mobile {
        @media (min-width: 1000px) {
            display: none;
        }
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        height: auto;
        align-items: flex-end;
        ul {
            display: none;
            flex-direction: column;
            width: 100%;
        }

        ul.open {
            display: flex;
        }

        ul li {
            width: 100%;
            text-align: right;
        }

        ul li a {
            margin: 0.2rem 0.5rem;
        }
    }
`;

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const { createNotification } = useNotifications();
    const { isAuthenticated, login, logout, user } = useAuth();
    return (
        <StyledNav>
            <StyledMenu onClick={() => setMenuOpen(!menuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </StyledMenu>
            <ul className={menuOpen ? 'open' : ''}>
                <li>
                    <NavLink to="/list" onClick={() => setMenuOpen(false)}>
                        DEMON LIST
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/leaderboard"
                        onClick={() => setMenuOpen(false)}
                    >
                        LEADERBOARD
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/packs" onClick={() => setMenuOpen(false)}>
                        PACKS
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/roulette" onClick={() => setMenuOpen(false)}>
                        ROULETTE
                    </NavLink>
                </li>
                <li className="mobile">
                    <hr />
                </li>
                {isAuthenticated ? (
                    <>
                        <li className="mobile">
                            <NavLink
                                to={`/profile/${user?.id ?? ''}`}
                                onClick={() => setMenuOpen(false)}
                            >
                                PROFILE
                            </NavLink>
                        </li>
                        <li className="mobile">
                            <NavLink
                                to="/submissions"
                                onClick={() => setMenuOpen(false)}
                            >
                                SUBMIT A RECORD
                            </NavLink>
                        </li>
                        <li className="mobile">
                            <NavLink
                                to="/settings"
                                onClick={() => setMenuOpen(false)}
                            >
                                SETTINGS
                            </NavLink>
                        </li>
                        <li className="mobile">
                            <Link
                                to="/"
                                onClick={() => {
                                    setMenuOpen(false);
                                    logout(createNotification);
                                }}
                            >
                                LOGOUT
                            </Link>
                        </li>{' '}
                    </>
                ) : (
                    <li className="mobile">
                        <Link
                            to="/"
                            onClick={() => {
                                setMenuOpen(false);
                                login(createNotification);
                            }}
                        >
                            LOGIN
                        </Link>
                    </li>
                )}
            </ul>
        </StyledNav>
    );
}

export default Navbar;
