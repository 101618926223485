import PartnerModal from 'components/Others/PartnerModal';
import { useState } from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';

const StyledPartner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 9vw;
    border-radius: 1vw;
    background: ${colors.backgroundDark};
    transition: transform 0.5s ease;
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    img {
        margin-top: 0.5vw;
        border-radius: 50%;
        width: 5vw;
        height: 5vw;
    }

    h1 {
        font-size: 1.25vw;
        flex-wrap: wrap;
    }

    p {
        font-size: 0.75vw;
        flex-wrap: wrap;
        text-align: center;
    }

    @media (max-width: 1000px) {
        width: 20vw;
        img {
            margin-top: 2vw;
            width: 12vw;
            height: 12vw;
        }
        h1 {
            font-size: 3vw;
        }
        p {
            font-size: 2vw;
        }
    }
`;

interface PartnerProps {
    icon: string;
    name: string;
    infoType: string;
}

function Partner(props: PartnerProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <StyledPartner onClick={() => setIsOpen(true)}>
                <img src={props.icon} alt={props.name} />
                <h1>{props.infoType}</h1>
                <p>{props.name}</p>
            </StyledPartner>
            <PartnerModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                infoType={props.infoType}
            />
        </>
    );
}

export default Partner;
