import { Loader } from 'components/Others/Loader';
import styled from 'styled-components';
import { useProfile } from 'utils/publicQueries';
import ErrorComponent from 'components/Others/Error';
import colors from 'utils/style/colors';
import { useCountryStore } from 'common/stores';
import ScrollWrapper from 'components/Layout/ScrollWrapper';

const ProfileSummaryWrapper = styled.section`
    display: flex;
    flex-direction: column;
    width: 35%;
    padding: 0 2.5%;

    @media (max-width: 1200px) {
        width: 100%;
        max-width: 35rem;
        margin-bottom: 3rem;
    }
`;

const ProfileSummaryUser = styled.div`
    align-items: center;
    text-align: center;
    width: 100%;
    img {
        width: 40%;
        border-radius: 50%;
    }

    .username {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        gap: 0.5rem;

        img {
            height: 2vw;
            border-radius: 0;
            width: fit-content;

            @media (max-width: 1200px) {
                height: 5vw;
            }
            @media (max-width: 1000px) {
                height: 6vw;
            }
        }
        p {
            margin: 0;
            color: ${colors.primary};
            font-size: 2vw;
            font-weight: 500;

            @media (max-width: 1200px) {
                font-size: 4.5vw;
            }
            @media (max-width: 1000px) {
                font-size: 6vw;
            }
        }
    }

    span {
        margin: 0;
        font-size: 1.5rem;
        text-transform: uppercase;
    }
`;

const ProfileSummaryRankings = styled.div`
    width: 80%;
    margin-top: 2rem;
    text-align: center;
    h3 {
        font-size: 1.5vw;
        @media (max-width: 1200px) {
            font-size: 3vw;
        }
        @media (max-width: 1000px) {
            font-size: 4vw;
        }
    }
    img {
        padding-right: 1rem;
        vertical-align: middle;
        display: inline;
        height: 2rem;
    }
    .rank {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 0.1rem solid ${colors.primaryLight};
        padding: 0.5rem;
        text-transform: uppercase;
        font-size: 1vw;
        @media (max-width: 1200px) {
            font-size: 2vw;
        }
        @media (max-width: 1000px) {
            font-size: 3vw;
        }
    }
`;

const ProfileSummaryPoints = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3vh;
    align-items: center;

    .points {
        color: ${colors.primary};
        text-align: center;
        width: 30%;
        text-transform: uppercase;

        p {
            font-size: 1vw;
            margin: 0;
            @media (max-width: 1200px) {
                font-size: 2vw;
            }
            @media (max-width: 1000px) {
                font-size: 3vw;
            }
        }
        span {
            font-size: 2vw;
            @media (max-width: 1200px) {
                font-size: 3vw;
            }
            @media (max-width: 1000px) {
                font-size: 4vw;
            }
            color: ${colors.primary};
            font-weight: 500;
            margin: 0;
        }

        &.center {
            width: 40%;
            p {
                font-size: 1.5vw;
                @media (max-width: 1200px) {
                    font-size: 3vw;
                }
                @media (max-width: 1000px) {
                    font-size: 4vw;
                }
            }
            span {
                font-size: 2.5vw;
                @media (max-width: 1200px) {
                    font-size: 4vw;
                }
                @media (max-width: 1000px) {
                    font-size: 5vw;
                }
            }
        }
    }
`;

function ProfileSummary() {
    const { data, isLoading, isError, error } = useProfile();
    const { getCountryByNumber } = useCountryStore();
    const avatarUrl =
        data != null && data?.discord_avatar
            ? `https://cdn.discordapp.com/avatars/${data.discord_id}/${data.discord_avatar}.` +
              (data?.discord_avatar?.startsWith('a_') ? 'gif' : 'png')
            : '/assets/avatar_default.svg';
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : !data ? (
                <Loader />
            ) : isError ? (
                error instanceof Error ? (
                    <ErrorComponent code="404" message={error.message} />
                ) : (
                    ''
                )
            ) : (
                <ProfileSummaryWrapper>
                    <ScrollWrapper $centercontent="true">
                        <ProfileSummaryUser>
                            <img src={avatarUrl} />
                            <div className="username">
                                {data.country ? (
                                    <img
                                        src={`/assets/flags/${getCountryByNumber(data.country)?.alpha2}.svg`}
                                        alt="User country flag"
                                    />
                                ) : (
                                    ''
                                )}
                                <p>{data.global_name}</p>
                                {data.roles
                                    ? data.roles.map((role) => (
                                          <img
                                              key={role.role_desc}
                                              src={`/assets/staff-icons/${role.role_desc}.svg`}
                                              alt={`User role ${role.role_desc}`}
                                          />
                                      ))
                                    : ''}
                            </div>
                            <span className="clan">
                                Clan: {data?.clan?.tag ?? 'none'}
                            </span>
                        </ProfileSummaryUser>
                        <ProfileSummaryPoints>
                            <div className="points">
                                <p>Levels points</p>
                                <span>
                                    {data.rank
                                        ? Math.round(
                                              (data.rank.total_points -
                                                  data.rank.pack_points) /
                                                  10,
                                          )
                                        : '-'}
                                </span>
                            </div>
                            <div className="points center">
                                <p>Total points</p>
                                <span>
                                    {data.rank
                                        ? Math.round(
                                              data.rank.total_points / 10,
                                          )
                                        : '-'}
                                </span>
                            </div>
                            <div className="points">
                                <p>Packs points</p>
                                <span>
                                    {data.rank
                                        ? Math.round(data.rank.pack_points / 10)
                                        : '-'}
                                </span>
                            </div>
                        </ProfileSummaryPoints>
                        <ProfileSummaryRankings>
                            <h3>
                                <img
                                    src="/assets/globalRank.webp"
                                    alt="global rank icon"
                                />
                                GLOBAL RANKINGS
                            </h3>
                            <div className="rank">
                                <div>Total Points Rank</div>
                                <span>#{data.rank ? data.rank.rank : '-'}</span>
                            </div>
                            <div className="rank">
                                <div>Level Points Rank</div>
                                <span>
                                    #{data.rank ? data.rank.raw_rank : '-'}
                                </span>
                            </div>
                            <div className="rank">
                                <div>Extremes Count Rank</div>
                                <span>
                                    #{data.rank ? data.rank.extremes_rank : '-'}
                                </span>
                            </div>
                        </ProfileSummaryRankings>
                        <ProfileSummaryRankings>
                            <h3>
                                <img
                                    src="/assets/countryRank.png"
                                    alt="global rank icon"
                                />
                                COUNTRY RANKINGS
                            </h3>
                            <div className="rank">
                                <div>Total Points Rank</div>
                                <span>
                                    #{data.rank ? data.rank.country_rank : '-'}
                                </span>
                            </div>
                            <div className="rank">
                                <div>Level Points Rank</div>
                                <span>
                                    #
                                    {data.rank
                                        ? data.rank.country_raw_rank
                                        : '-'}
                                </span>
                            </div>
                            <div className="rank">
                                <div>Extremes Count Rank</div>
                                <span>
                                    #
                                    {data.rank
                                        ? data.rank.country_extremes_rank
                                        : '-'}
                                </span>
                            </div>
                        </ProfileSummaryRankings>
                    </ScrollWrapper>
                </ProfileSummaryWrapper>
            )}
        </>
    );
}

export default ProfileSummary;
