import { BaseLevel } from 'common/types/API';
import { Error as ErrorComponent } from 'components';
import { Dropdown, DropdownItem } from 'components/Layout/Dropdown';
import { Loader } from 'components/Others/Loader';
import Search from 'components/Others/Search';
import { useEffect, useState } from 'react';
import { useLevels } from 'utils/publicQueries';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { putSubmission } from 'utils/authedQueries';
import { Color } from 'components/Others/Notification';
import { useNotifications } from 'common/stores';
import { useQueryClient } from 'react-query';
import { StyledInput } from 'components/Layout/Input';

interface SubmissionData {
    level: string;
    video_url: string;
    mobile: boolean;
    ldm_id: number | null;
    raw_footage: string | null;
    additional_notes: string | null;
}

export type { SubmissionData };

const FormContainer = styled.div`
    text-align: center;
    background-color: ${colors.backgroundDark};
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 2rem;
    text-transform: uppercase;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`;

const InputGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
`;

const StyledButton = styled.button`
    background-color: ${colors.secondary};
    color: ${colors.primary};
    border: none;
    width: 80%;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 0.3rem;
    margin: auto;
    transition: transform 0.5s;
    &:hover {
        transform: scale(1.1);
    }
`;

const PlacementContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
`;

interface LevelDropdownProps {
    level: BaseLevel | undefined;
    setLevel: (level: BaseLevel) => void;
    list: BaseLevel[];
    openabove?: boolean;
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
}
function LevelDropdown(props: LevelDropdownProps) {
    return (
        <Dropdown
            buttonText={
                props.level
                    ? `#${props.level.position} ${props.level.name}`
                    : 'Select level'
            }
            content={
                <>
                    <Search input={props.search} setinput={props.setSearch} />
                    {props.list.length > 0
                        ? props.list.map((current_level) => (
                              <DropdownItem
                                  key={current_level.id}
                                  onClick={() => props.setLevel(current_level)}
                              >
                                  {`#${current_level.position} ${current_level.name}`}
                              </DropdownItem>
                          ))
                        : 'No results'}
                </>
            }
        />
    );
}

function Submit() {
    const [selectedLevel, setSelectedLevel] = useState<BaseLevel>();
    const [harderThan, setHarderThan] = useState<BaseLevel>();
    const [easierThan, setEasierThan] = useState<BaseLevel>();
    const { data: levels, isLoading, isError, error } = useLevels('classic');
    const [search, setSearch] = useState('');
    const mainList = levels?.filter((level: BaseLevel) => !level.legacy);
    const filteredLevels = mainList?.filter((level: BaseLevel) => {
        return level.name.toLowerCase().includes(search.toLowerCase());
    });

    const { createNotification } = useNotifications();
    const [formState, setFormState] = useState<SubmissionData>({
        level: '',
        video_url: '',
        mobile: false,
        ldm_id: null,
        raw_footage: null,
        additional_notes: null,
    });

    const queryClient = useQueryClient();
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ): void => {
        const { name, value } = event.target;
        setFormState((prevFormData) => ({
            ...prevFormData,
            [name]: value === '' ? null : value,
        }));
    };

    useEffect(() => {
        setFormState((prevFormData) => ({
            ...prevFormData,
            level: selectedLevel?.id ?? '',
        }));
    }, [selectedLevel]);

    const handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        putSubmission(formState).then((response) => {
            if (!response.error) {
                createNotification(
                    Color.success,
                    'Record submitted successfully',
                );
                queryClient.invalidateQueries('submissions');
            } else {
                createNotification(Color.error, response.data.message);
            }
        });
        setFormState({
            level: '',
            video_url: '',
            mobile: false,
            ldm_id: null,
            raw_footage: null,
            additional_notes: null,
        });
        setSelectedLevel(undefined);
    };

    return (
        <FormContainer>
            {isLoading ? (
                <Loader />
            ) : !filteredLevels ? (
                <Loader />
            ) : isError ? (
                error instanceof Error ? (
                    <ErrorComponent message={error.message} />
                ) : (
                    ''
                )
            ) : (
                <>
                    <h1>RECORD SUBMISSION</h1>
                    <form onSubmit={handleSubmit}>
                        <InputGroup>
                            <LevelDropdown
                                level={selectedLevel}
                                setLevel={setSelectedLevel}
                                search={search}
                                setSearch={setSearch}
                                list={filteredLevels}
                            />

                            <StyledInput
                                type="url"
                                placeholder="VIDEO LINK"
                                name="video_url"
                                value={formState.video_url ?? ''}
                                onChange={handleInputChange}
                                required
                            />

                            <Dropdown
                                buttonText={formState.mobile ? 'Mobile' : 'PC'}
                                content={
                                    <>
                                        <DropdownItem
                                            onClick={() =>
                                                setFormState(
                                                    (prevFormData) => ({
                                                        ...prevFormData,
                                                        mobile: false,
                                                    }),
                                                )
                                            }
                                        >
                                            PC
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                setFormState(
                                                    (prevFormData) => ({
                                                        ...prevFormData,
                                                        mobile: true,
                                                    }),
                                                )
                                            }
                                        >
                                            Mobile
                                        </DropdownItem>
                                    </>
                                }
                            />
                        </InputGroup>
                        <InputGroup>
                            <StyledInput
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                id="ldm_id"
                                placeholder="LDM ID (OPTIONAL)"
                                name="ldm_id"
                                value={formState.ldm_id ?? ''}
                                onChange={handleInputChange}
                            />
                            <StyledInput
                                type="url"
                                id="raw_footage"
                                placeholder="RAW FOOTAGE (OPTIONAL)"
                                name="raw_footage"
                                value={formState.raw_footage ?? ''}
                                onChange={handleInputChange}
                            />
                            <StyledInput
                                type="text"
                                id="additional_notes"
                                placeholder="NOTES (OPTIONAL)"
                                name="additional_notes"
                                value={formState.additional_notes ?? ''}
                                onChange={handleInputChange}
                            />
                        </InputGroup>
                        <h2>PLACEMENT OPINION</h2>
                        <InputGroup>
                            <PlacementContainer>
                                Harder than (optional):
                                <LevelDropdown
                                    level={harderThan}
                                    setLevel={setHarderThan}
                                    search={search}
                                    setSearch={setSearch}
                                    list={filteredLevels}
                                    openabove={true}
                                />
                            </PlacementContainer>

                            <PlacementContainer>
                                Easier than (optional):
                                <LevelDropdown
                                    level={easierThan}
                                    setLevel={setEasierThan}
                                    search={search}
                                    setSearch={setSearch}
                                    list={filteredLevels}
                                    openabove={true}
                                />
                            </PlacementContainer>
                        </InputGroup>
                        <p>
                            By submitting this record, you acknowledge that it
                            follows the <span>submission guidelines</span>.
                        </p>
                        <StyledButton type="submit">SUBMIT RECORD</StyledButton>
                    </form>
                </>
            )}
        </FormContainer>
    );
}

export default Submit;
