import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CardWrapper from 'components/Layout/CardWrapper';
import colors from 'utils/style/colors';
import Search from '../Others/Search';
import { useListStore } from 'common/stores';
import { useIDL, useLevels } from 'utils/publicQueries';
import ValueSwitch from 'components/Layout/ValueSwitch';
import { Loader } from 'components/Others/Loader';
import { BaseLevel } from 'common/types/API';
import ScrollWrapper from 'components/Layout/ScrollWrapper';
import ErrorComponent from 'components/Others/Error';
import { FaFilter } from 'react-icons/fa6';
import ListSettingsModal from './ListSettings';

interface LevelProps {
    level: BaseLevel;
    search: string;
    idlpos?: number;
}

interface LevelsProps {
    className?: string;
}

const StyledValueSwitch = styled(ValueSwitch)`
    margin: 1rem 0;
`;

const StyledLevelLink = styled.li`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background: ${colors.backgroundLightTransparent};
    margin: 0.1vh 0;
    padding: 0.25vh;
    white-space: nowrap;
    align-items: center;
    border-radius: 0.25rem;
    font-weight: 400;

    p {
        margin: 0;
        display: block;
        font-size: 2vh;

        span {
            color: ${colors.primaryLight};
        }
    }

    .level-pos {
        padding: 0 0.5rem;
        width: 5rem;
        text-align: center;
    }

    .level-name {
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding-right: 1rem;
        width: 100%;
    }

    &:not(.focus):hover {
        background-color: ${colors.primaryLight};
        transition: background-color 0.25s;
        cursor: pointer;
    }

    &.focus {
        background-color: ${colors.secondary};
        width: fit-content;
        max-width: 100%;
    }
`;

const ListControl = styled.div`
    width: 90%;
    gap: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    .filter {
        font-size: 2rem;
        transition: color 0.5s;
        color: ${colors.primaryLight};
        cursor: pointer;
        &:hover {
            color: ${colors.primary};
        }
    }
`;

const ListLevelLink = React.forwardRef<HTMLLIElement, LevelProps>(
    (props, ref) => {
        const { selectLevel, selectedLevel, listType } = useListStore();
        const navigate = useNavigate();
        const { data } = useLevels(listType);
        const list = data ?? [];

        useEffect(() => {
            if (
                props.level.id === selectedLevel &&
                ref &&
                'current' in ref &&
                ref.current
            ) {
                ref.current?.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                });
            }
        }, [props.search]);

        return (
            <StyledLevelLink
                ref={ref}
                onClick={() => {
                    if (selectLevel(props.level.id, list)) {
                        navigate(
                            `/list/${props.level.level_id}/${props.level.two_player ? '2p' : ''}`,
                        );
                    }
                }}
                className={props.level.id === selectedLevel ? 'focus' : ''}
            >
                <p className="level-pos">
                    {!props.level.legacy ? `#${props.level.position}` : ' - '}
                </p>
                <p className="level-name">
                    {props.level.name}{' '}
                    {props.idlpos && props.idlpos != -1 ? (
                        <span>(IDL #{props.idlpos})</span>
                    ) : (
                        ''
                    )}
                </p>
            </StyledLevelLink>
        );
    },
);
ListLevelLink.displayName = 'LevelLink';

function Levels(props: LevelsProps) {
    const [search, setSearch] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const { level_id, is_2p } = useParams();
    const { listType, filters, sort, setListType, selectLevel } =
        useListStore();
    const { data, error, isLoading, isError } = useLevels(listType);
    const { data: dataIDL } = useIDL();
    const list = data ?? [];
    const navigate = useNavigate();
    const refMap = useRef<Map<string, React.RefObject<HTMLLIElement>>>(
        new Map(),
    );

    const getSortedFilteredList = () => {
        return list
            .filter(
                (level) =>
                    level.name.toLowerCase().includes(search.toLowerCase()) &&
                    Object.keys(filters.tags).every(
                        (tag) =>
                            level.tags &&
                            level.tags.includes(tag) === filters.tags[tag],
                    ) &&
                    filters.ranges.every(({ property, range }) => {
                        const value = level[property];
                        if (range.min === undefined && range.max === undefined)
                            return true;
                        if (value == null) return false;
                        return (
                            (range.min !== undefined
                                ? Number(value) >= range.min
                                : true) &&
                            (range.max !== undefined
                                ? Number(value) <= range.max
                                : true)
                        );
                    }),
            )
            .sort((levelA, levelB) => {
                if (sort === 'rank') return 0;
                else if (sort === 'enjoyment') {
                    return levelA?.edel_enjoyment && levelB.edel_enjoyment
                        ? levelB.edel_enjoyment - levelA.edel_enjoyment
                        : levelA?.edel_enjoyment
                          ? -1
                          : levelB?.edel_enjoyment
                            ? 1
                            : 0;
                } else return 0;
            });
    };

    // Memoize lists
    const sortedList = useMemo(
        () => getSortedFilteredList(),
        [list, search, filters, sort],
    );

    const filteredMainList = useMemo(
        () => sortedList.filter((level) => !level.legacy),
        [sortedList],
    );

    const filteredLegacyList = useMemo(
        () => sortedList.filter((level) => level.legacy),
        [sortedList],
    );

    const legacyListIDL = useMemo(() => {
        if (!dataIDL) return [];
        return filteredLegacyList
            .map((level) => {
                const levelIDL = dataIDL.find(
                    (levelIDL) => levelIDL.name === level.name,
                );
                return {
                    level: level,
                    idlpos: levelIDL ? levelIDL.position : -1,
                };
            })
            .sort((a, b) =>
                a.idlpos !== -1 && b.idlpos !== -1
                    ? a.idlpos - b.idlpos
                    : a.idlpos !== -1
                      ? -1
                      : 1,
            );
    }, [filteredLegacyList, dataIDL]);

    // Level selection logic (ran on page load/URL change)
    useEffect(() => {
        if (isLoading || isError || list.length == 0) return;

        // Search for a level either using internal ID or GD level ID
        const level =
            (level_id?.length === 36
                ? list.find((l) => l.id === level_id)
                : list.find(
                      (l) =>
                          l.level_id.toString() === level_id &&
                          l.two_player === (is_2p === '2p'),
                  )) || list[0]; // Defaulting to first one

        // Scroll to the selected level, and update URL (only display GD level ID instead of the internal one)
        if (selectLevel(level.id, list)) {
            refMap.current.get(`${level.id}`)?.current?.scrollIntoView({
                behavior: 'auto',
                block: 'center',
            });

            navigate(
                `/list/${level.level_id}${level.two_player ? '/2p' : ''}`,
                { replace: true },
            );
        }
    }, [isLoading, level_id, is_2p]);

    // Add refs for autoscroll
    filteredMainList.forEach((level: BaseLevel) => {
        refMap.current.set(`${level.id}`, React.createRef<HTMLLIElement>());
    });
    filteredLegacyList.forEach((level: BaseLevel) => {
        refMap.current.set(`${level.id}`, React.createRef<HTMLLIElement>());
    });

    return (
        <CardWrapper className={props.className}>
            <StyledValueSwitch
                options={['classic', 'platformer']}
                current={listType}
                set={setListType}
            />
            <ListControl>
                <Search input={search} setinput={setSearch} />
                <FaFilter
                    className="filter"
                    onClick={() => setModalOpen(true)}
                />
            </ListControl>

            {isLoading ? (
                <Loader />
            ) : isError ? (
                error instanceof Error ? (
                    <ErrorComponent message={error.message} />
                ) : (
                    ''
                )
            ) : (
                <ScrollWrapper>
                    {filteredMainList.map((level: BaseLevel) => {
                        const ref = refMap.current.get(`${level.id}`);
                        return (
                            <ListLevelLink
                                key={`level-${level.level_id}-${level.position}`}
                                level={level}
                                search={search}
                                ref={ref}
                            />
                        );
                    })}
                    {filteredLegacyList.length > 0 && (
                        <>
                            <h3>Legacy List</h3>
                            {legacyListIDL.map((idllevel) => {
                                const ref = refMap.current.get(
                                    `${idllevel.level.id}`,
                                );
                                return (
                                    <ListLevelLink
                                        key={`level-${idllevel.level.level_id}-${idllevel.level.position}`}
                                        level={idllevel.level}
                                        idlpos={idllevel.idlpos}
                                        search={search}
                                        ref={ref}
                                    />
                                );
                            })}
                        </>
                    )}
                </ScrollWrapper>
            )}
            <ListSettingsModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
            />
        </CardWrapper>
    );
}

export default Levels;
