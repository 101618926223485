import CardWrapper from 'components/Layout/CardWrapper';
import LeaderboardHeader from 'components/Leaderboard/LeaderboardHeader';
import LeaderboardTable from 'components/Leaderboard/LeaderboardTable';
import Pagination from 'components/Layout/Pagination';
import { useLeaderboardStore } from 'common/stores';
import { useLeaderboard } from 'utils/publicQueries';
import ScrollWrapper from 'components/Layout/ScrollWrapper';

function Leaderboard() {
    const { page, setPage } = useLeaderboardStore();
    const { data, isLoading, isError } = useLeaderboard();

    return (
        <main>
            <CardWrapper height="85vh">
                <LeaderboardHeader />
                <ScrollWrapper>
                    <LeaderboardTable />
                </ScrollWrapper>

                {!isLoading && !isError && data ? (
                    <Pagination
                        totalPages={data.pages}
                        currentPage={page}
                        onPageChange={(newPage: number) => {
                            if (newPage > 0 && page <= data.pages)
                                setPage(newPage);
                        }}
                    />
                ) : (
                    ''
                )}
            </CardWrapper>
        </main>
    );
}

export default Leaderboard;
