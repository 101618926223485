const colors = {
    primary: '#FFFFFF',
    primaryLight: '#616062',
    secondary: '#FF6F00',
    secondaryLight: 'rgba(255, 111, 0, 0.2)',
    backgroundLight: '#1C1B1F',
    backgroundSemiLight: '#1E1D21',
    backgroundLightTransparent: 'rgba(255,255,255,0.03)',
    modalBackground: 'rgba(28,27,31,0.95)',
    backgroundDark: 'rgba(0,0,0,0.17)',
    backgroundDarker: 'rgba(0,0,0,0.25)',
    confirm: 'rgb(0,100,0)',
    confirmTransparent: 'rgba(0,100,0,0.3)',
    cancel: 'rgb(100,0,0)',
    pending: '#6082B6',
    pendingTransparent: '#6082B630',
    cancelTransparent: 'rgba(100,0,0,0.3)',
};

export default colors;
