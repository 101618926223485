import styled from 'styled-components';
import colors from 'utils/style/colors';
import { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface ScrollWrapperProps {
    children: ReactNode;
    height?: string;
    wrap?: 'true' | 'false';
    $centercontent?: 'true' | 'false';
    $scrolldirection?: 'vertical' | 'horizontal' | 'both';
}

interface StyledContainerProps {
    height: string;
    wrap: string;
    $centercontent: string;
    $scrolldirection: 'vertical' | 'horizontal' | 'both';
}

const ScrollWrapper: React.FC<ScrollWrapperProps> = ({
    children,
    height = 'auto',
    wrap = 'false',
    $centercontent = 'false',
    $scrolldirection = 'vertical',
}) => {
    return (
        <StyledScrollContainer
            className={navigator.userAgent.includes('Firefox') ? 'firefox' : ''}
            height={height}
            wrap={wrap}
            $centercontent={$centercontent}
            $scrolldirection={$scrolldirection}
        >
            {children}
        </StyledScrollContainer>
    );
};

const StyledScrollContainer = styled.div<StyledContainerProps>`
    max-height: ${(props) => props.height};
    min-height: ${(props) => props.height};
    display: flex;
    flex-direction: column;
    overflow-y: ${(props) =>
        props.$scrolldirection === 'vertical' ||
        props.$scrolldirection === 'both'
            ? 'auto'
            : 'hidden'};
    overflow-x: ${(props) =>
        props.$scrolldirection === 'horizontal' ||
        props.$scrolldirection === 'both'
            ? 'auto'
            : 'hidden'};
    padding: 0.25rem;
    width: 100%;
    flex-wrap: ${(props) => (props.wrap === 'true' ? 'wrap' : 'nowrap')};
    color: ${colors.primary};
    margin-right: 0.5rem;
    align-items: ${(props) =>
        props.$centercontent === 'true' ? 'center' : 'flex-start'};
    box-sizing: border-box;
    position: relative;

    &::-webkit-scrollbar {
        width: ${(props) =>
            props.$scrolldirection === 'vertical' ||
            props.$scrolldirection === 'both'
                ? '0.5rem'
                : '0'};
        height: ${(props) =>
            props.$scrolldirection === 'horizontal' ||
            props.$scrolldirection === 'both'
                ? '0.5rem'
                : '0'};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${colors.secondary};
        border-radius: 0.5rem;
        border: 0.25rem solid ${colors.secondary};
    }

    &::-webkit-scrollbar-track {
        margin: 1rem 0;
        background-color: transparent;
    }

    scrollbar-width: thin;
    scrollbar-color: ${colors.secondary} transparent;

    @media (max-width: 1000px) {
        padding: 0;
    }
`;

ScrollWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    height: PropTypes.string,
    wrap: PropTypes.oneOf(['true', 'false']),
    $centercontent: PropTypes.oneOf(['true', 'false']),
    $scrolldirection: PropTypes.oneOf(['vertical', 'horizontal', 'both']),
};

export default ScrollWrapper;
