import { useLevelRecords, useLevels } from 'utils/publicQueries';
import { Loader } from 'components/Others/Loader';
import CardWrapper from 'components/Layout/CardWrapper';
import { useState } from 'react';
import Search from 'components/Others/Search';
import { LevelRecord } from 'common/types/API';
import { UserLink } from 'components/Others/UserDisplay';
import ScrollWrapper from 'components/Layout/ScrollWrapper';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { useListStore } from 'common/stores';
import ErrorComponent from 'components/Others/Error';
import { Link } from 'react-router-dom';

interface RecordProps {
    record: LevelRecord;
}

interface RecordsInfoProps {
    className?: string;
}

const Title = styled.h2`
    border-bottom: 1px solid ${colors.primaryLight};
    padding-left: 1rem;
    padding-bottom: 0.25rem;
    align-self: flex-start;
    width: 100%;
    box-sizing: border-box;
    overflow-x: clip;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 2rem;
`;
const UserLinkWrapper = styled.div`
    margin-left: 2rem;
    text-align: left;
    flex-grow: 1;
    overflow-x: hidden;
    span {
        padding-left: 0.25rem;
        color: ${colors.primaryLight};
    }

    a {
        display: inline-block;
    }
`;

const SearchWrapper = styled.div`
    width: 80%;
`;
const StyledRecord = styled.li`
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    color: ${colors.primary};
    align-items: center;
    overflow-x: clip;
    margin: 0.25rem 0;

    h3 {
        align-self: center;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        padding-left: 1rem;
        height: auto;
    }

    img {
        height: 2em;
        vertical-align: middle;
        transition: transform 0.5s;

        &:hover {
            transform: scale(1.05);
        }
    }
`;

function Record(props: RecordProps) {
    return (
        <StyledRecord>
            {props.record.submitted_by.country ? (
                <img
                    src={`&/assets/flags/${props.record.submitted_by.country}`}
                    alt="Country flag"
                />
            ) : (
                <h3>-</h3>
            )}
            <UserLinkWrapper>
                <UserLink user={props.record.submitted_by} />
                {props.record.mobile ? <span>(Mobile)</span> : ''}
            </UserLinkWrapper>
            <Link to={props.record.video_url} target="_about:blank">
                <img src={'/assets/youtube.svg'} alt="Youtube link" />
            </Link>
        </StyledRecord>
    );
}

function RecordsInfo(props: RecordsInfoProps) {
    const { listType, selectedLevel } = useListStore();
    const { data, error, isLoading, isError } = useLevelRecords(
        listType,
        selectedLevel,
    );
    const { isLoading: isListLoading, isError: isListError } =
        useLevels(listType);

    const [search, setSearch] = useState('');

    const records = data ?? [];
    return (
        <CardWrapper className={props.className}>
            {isLoading || isListLoading ? (
                <Loader />
            ) : !data ? (
                <Loader />
            ) : isListError ? (
                <ErrorComponent message="List failed to load" />
            ) : isError ? (
                error instanceof Error ? (
                    <ErrorComponent message={error.message} />
                ) : (
                    ''
                )
            ) : (
                <>
                    <Title>
                        {records.length > 0
                            ? `Records (${records.length})`
                            : 'No records'}
                    </Title>
                    {records.length > 0 ? (
                        <SearchWrapper>
                            <Search input={search} setinput={setSearch} />
                        </SearchWrapper>
                    ) : (
                        ''
                    )}
                    <ScrollWrapper>
                        {records
                            .filter((record: LevelRecord) => {
                                return record.submitted_by.global_name
                                    .toLowerCase()
                                    .includes(search.toLowerCase());
                            })
                            .map((record: LevelRecord, index: number) => (
                                <Record
                                    key={`${record.submitted_by.id}-${index}`}
                                    record={record}
                                />
                            ))}
                    </ScrollWrapper>
                </>
            )}
        </CardWrapper>
    );
}

export default RecordsInfo;
