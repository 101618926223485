import styled from 'styled-components';

import colors from 'utils/style/colors';

import Guidelines from 'components/Submissions/Guidelines';
import Submit from 'components/Submissions/Submit';
import SubmissionsList from 'components/Submissions/SubmissionsList';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.primary};
    padding: 1rem;
    gap: 1rem;

    @media (min-width: 1000px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const SubmitContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    width: 100%;
    gap: 1rem;

    @media (min-width: 1000px) {
        width: 65%;
    }
`;

function Submissions() {
    return (
        <Container>
            <SubmitContainer>
                <Submit />
                <SubmissionsList />
            </SubmitContainer>
            <Guidelines />
        </Container>
    );
}

export default Submissions;
