import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Color } from 'components/Others/Notification';
import { User } from 'common/types/API';
import backend from 'common/backend';

interface AuthState {
    isAuthenticated: boolean;
    token: string | null;
    expires: string | null;
    user: User | null;
    setUser: (user: User) => void;
    setToken: (token: string) => void;
    setExpires: (expires: string) => void;
    setAuthState: (isAuthenticated: boolean) => void;
    login: (
        createNotification: (color: keyof typeof Color, text: string) => void,
    ) => void;
    logout: (
        createNotification: (color: keyof typeof Color, text: string) => void,
    ) => void;
    updateAuthedUser: () => void;
}

const useAuth = create<AuthState>()(
    persist(
        (set, get) => ({
            isAuthenticated: false,
            token: null,
            expires: null,
            user: null,
            setUser: (user) => set({ user }),
            setToken: (token) => set({ token }),
            setExpires: (expires) => set({ expires }),
            setAuthState(isAuthenticated) {
                set({ isAuthenticated });
            },
            login: async (createNotification) => {
                try {
                    const authWindow = window.open(
                        `${backend.baseUrl}/auth/discord?opener_origin=${encodeURIComponent(window.location.origin)}`,
                        'Discord Auth',
                        'width=500,height=800,popup=yes',
                    );

                    if (!authWindow) {
                        createNotification(
                            Color.error,
                            'Failed to open the login window',
                        );
                        return;
                    }

                    const messageListener = async (event: MessageEvent) => {
                        const data = event.data?.data;
                        if (
                            event.origin !== new URL(backend.baseUrl).origin ||
                            !data?.token ||
                            !data?.expires ||
                            !data?.user
                        )
                            return;

                        window.removeEventListener('message', messageListener);
                        if (!authWindow?.closed) {
                            authWindow?.close();
                        }

                        set({
                            isAuthenticated: true,
                            token: data.token,
                            expires: data.expires,
                            user: data.user,
                        });

                        createNotification(
                            Color.success,
                            'Logged in successfully',
                        );
                    };

                    const windowCloseInterval = setInterval(() => {
                        if (authWindow.closed) {
                            clearInterval(windowCloseInterval);
                            if (!get().isAuthenticated) {
                                window.removeEventListener(
                                    'message',
                                    messageListener,
                                );
                                createNotification(
                                    Color.error,
                                    'Login canceled',
                                );
                            }
                        }
                    }, 500);
                    window.addEventListener('message', messageListener);
                } catch (error) {
                    createNotification(Color.error, (error as Error).message);
                }
            },

            logout: async (createNotification) => {
                /*const response = await backend.send(
                    '/auth/logout',
                    'POST',
                    undefined,
                    undefined,
                    true,
                );

                if (!response.error) {
                    set({
                        isAuthenticated: false,
                        user: null,
                    });
                    createNotification(
                        Color.success,
                        'Logged out successfully',
                    );
                } else {
                    createNotification(Color.error, response.data.message);
                }*/
                set({
                    isAuthenticated: false,
                    user: null,
                });
                createNotification(Color.success, 'Logged out successfully');
            },
            updateAuthedUser: async () => {
                if (get().isAuthenticated) {
                    try {
                        backend.send('/users/@me', 'GET').then((response) => {
                            if (response.error) {
                                set({
                                    isAuthenticated: false,
                                    token: null,
                                    expires: null,
                                    user: null,
                                });
                            } else {
                                set({ user: response.data });
                            }
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
        }),
        {
            name: 'auth-storage',
        },
    ),
);

export default useAuth;
