import styled from 'styled-components';
import colors from 'utils/style/colors';
import PropTypes from 'prop-types';

interface StyledButtonProps {
    $cancel?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
    background-color: ${({ $cancel }) =>
        $cancel ? colors.backgroundSemiLight : colors.secondary};
    color: ${colors.primary};
    border: ${({ $cancel }) =>
        $cancel ? `0.05rem solid ${colors.primaryLight}` : 'none'};
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 0.3rem;
    margin: auto;
    transition: transform 0.5s;
    &:hover {
        transform: scale(1.1);
    }
    svg {
        padding: 0 0.5rem;
        vertical-align: middle;
        width: 2rem;
        height: 2rem;
    }
`;

StyledButton.propTypes = {
    $cancel: PropTypes.bool,
};

export default StyledButton;
