import { Loader } from 'components/Others/Loader';
import CardWrapper from 'components/Layout/CardWrapper';
import YoutubeEmbed from 'components/Others/YoutubeEmbed';
import styled from 'styled-components';

import { PiWarningCircle } from 'react-icons/pi';
import { FaInfoCircle } from 'react-icons/fa';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ErrorComponent from 'components/Others/Error';
import colors from 'utils/style/colors';
import {
    useIDL,
    useLevels,
    useBaseLevel,
    useLevelCreators,
    useLevelPacks,
} from 'utils/publicQueries';
import { UsersList } from 'components/Others/UserDisplay';
import { useListStore } from 'common/stores';
import { PacksWrapper, TagsWrapper } from './PacksTagsWrapper';
import History from './LevelHistory';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import PartnerModal from 'components/Others/PartnerModal';
import ScrollWrapper from 'components/Layout/ScrollWrapper';

interface LevelData {
    label: string;
    data: string;
    link?: string;
    info?: string;
    warn?: string;
}

interface LevelInfoProps {
    className?: string;
}

const LevelDataWrapper = styled.div`
    display: flex;
    width: 90%;
    margin-bottom: 0.5rem;
`;

const StyledLevelData = styled.div`
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    padding: 0.1rem;
    border-right: 0.1rem solid ${colors.primaryLight};
    &:last-child {
        border-right: none;
    }

    svg {
        cursor: pointer;
        color: ${colors.secondary};

        &.external {
            font-size: clamp(0.7rem, 1vw, 1rem);
            vertical-align: super;
        }
    }

    .label {
        display: block;
        color: ${colors.secondary};
        font-size: clamp(0.5rem, 1vw, 1rem);
        white-space: nowrap;
    }

    .data {
        display: flex;
        color: ${colors.primary};
        font-size: clamp(1.5rem, 2vw, 2.5rem);
        justify-content: center;
        align-items: center;
    }
`;

const Title = styled.h1`
    border-bottom: 1px solid ${colors.primaryLight};
    padding-left: 1rem;
    padding-bottom: 0.25rem;
    align-self: flex-start;
    width: 100%;
    box-sizing: border-box;
    overflow-x: clip;
    text-overflow: ellipsis;
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0.5rem 0 0 0;
`;

const Description = styled.p`
    align-self: flex-start;
    padding-left: 1rem;
    color: ${colors.primary};
    margin: 0.25rem 0;
`;

const SubTitle = styled.h2`
    font-weight: 300;
    font-size: 2rem;
    padding: 0;
    margin: 0.5rem 0;
`;

const LevelUsersWrapper = styled.div`
    margin-top: 0.5rem;
    width: 80%;
    display: grid;
    grid-template-columns: max-content 3fr;
    grid-auto-rows: max-content;
    gap: 1rem 1rem;
    align-items: first baseline;
    align-self: center;
    text-align: justify;
    white-space: pre;

    span {
        text-transform: uppercase;
        color: ${colors.secondary};
        margin: 0;
        font-weight: 300;
    }
`;

function LevelDataInfo(props: LevelData) {
    const [isMainModalOpen, setMainModalOpen] = useState(false);
    const [isWarnModalOpen, setWarnModalOpen] = useState(false);
    return (
        <StyledLevelData>
            <span className="label">
                {props.label}{' '}
                {props.info ? (
                    <FaInfoCircle onClick={() => setMainModalOpen(true)} />
                ) : (
                    ''
                )}
            </span>
            <span className="data">
                {props.link ? (
                    <Link target="_blank" to={props.link}>
                        {props.data}
                        <FaExternalLinkAlt className="external" />
                    </Link>
                ) : (
                    props.data
                )}
                {props.warn ? (
                    <PiWarningCircle onClick={() => setWarnModalOpen(true)} />
                ) : (
                    ''
                )}
            </span>
            {props.info ? (
                <PartnerModal
                    isOpen={isMainModalOpen}
                    onClose={() => setMainModalOpen(false)}
                    infoType={props.info}
                />
            ) : (
                ''
            )}
            {props.warn ? (
                <PartnerModal
                    isOpen={isWarnModalOpen}
                    onClose={() => setWarnModalOpen(false)}
                    infoType={props.warn}
                />
            ) : (
                ''
            )}
        </StyledLevelData>
    );
}

function LevelInfo(props: LevelInfoProps) {
    const { listType, selectedLevel } = useListStore();
    const { isLoading: isListLoading, isError: isListError } =
        useLevels(listType);
    const {
        data: baseData,
        error: baseError,
        isLoading: baseIsLoading,
        isError: baseIsError,
    } = useBaseLevel(listType, selectedLevel);
    const {
        data: creatorsData,
        isLoading: creatorsIsLoading,
        isError: creatorsIsError,
    } = useLevelCreators(listType, selectedLevel);
    const {
        data: packsData,
        isLoading: packsIsLoading,
        isError: packsIsError,
    } = useLevelPacks(listType, selectedLevel);

    const { data: dataIDL } = useIDL();
    let idlpos = -1;
    let idlid = '';

    if (baseData) {
        const levelIDL = dataIDL?.find(
            (idllevel) => idllevel.name === baseData.name,
        );
        if (baseData.legacy) idlpos = levelIDL?.position ?? -1;
        else idlpos = levelIDL?.formerRank ?? -1;
        idlid = levelIDL?.id ?? '';
    }
    return (
        <CardWrapper className={props.className}>
            {baseIsLoading || isListLoading ? (
                <Loader />
            ) : isListError ? (
                <ErrorComponent message="List failed to load" />
            ) : baseIsError ? (
                baseError instanceof Error ? (
                    <ErrorComponent message={baseError.message} />
                ) : (
                    ''
                )
            ) : !baseData ? (
                <Loader />
            ) : (
                <>
                    <Title>{baseData.name}</Title>
                    <Description>
                        {baseData.description ??
                            'No description was provided for this level yet'}
                    </Description>

                    <ScrollWrapper $centercontent="true">
                        {!packsIsError && !packsIsLoading && packsData ? (
                            <PacksWrapper packs={packsData} />
                        ) : (
                            ''
                        )}
                        <YoutubeEmbed link={baseData.verification.video_url} />
                        {baseData.tags ? (
                            <TagsWrapper tags={baseData.tags} />
                        ) : (
                            ''
                        )}

                        <LevelDataWrapper>
                            <LevelDataInfo
                                label="level id"
                                data={baseData.level_id}
                            />
                            {baseData.legacy ? (
                                <LevelDataInfo
                                    label="idl rank"
                                    data={idlpos != -1 ? `#${idlpos}` : '-'}
                                    link={`https://insanedemonlist.com/level/${idlid}`}
                                    info="IDL"
                                />
                            ) : (
                                <LevelDataInfo
                                    label="list points"
                                    data={String(baseData.points / 10 ?? '-')}
                                />
                            )}

                            <LevelDataInfo
                                label="edel enjoyment"
                                data={String(baseData.edel_enjoyment ?? '-')}
                                info="EDEL"
                                warn={
                                    baseData.is_edel_pending
                                        ? 'EDEL_PENDING'
                                        : ''
                                }
                            />
                        </LevelDataWrapper>

                        <LevelDataWrapper>
                            <LevelDataInfo
                                label={baseData.song ? 'song' : 'nong download'}
                                data={baseData.song ? baseData.song : 'SFH'}
                                link={
                                    baseData.song
                                        ? `https://www.newgrounds.com/audio/listen/${baseData.song}`
                                        : `https://songfilehub.com/home?name=${baseData.name}`
                                }
                                info={baseData.song ? '' : 'SFH'}
                            />
                            {!baseData.legacy && idlpos != -1 ? (
                                <LevelDataInfo
                                    label="idl rank before rerate"
                                    data={`#${idlpos}`}
                                    link={`https://insanedemonlist.com/level/${idlid}`}
                                    info="IDL"
                                />
                            ) : (
                                ''
                            )}
                            <LevelDataInfo
                                label="gddl tier"
                                data={String(
                                    baseData.gddl_tier
                                        ? Math.round(baseData.gddl_tier)
                                        : '-',
                                )}
                                link={
                                    baseData.gddl_tier
                                        ? `https://gdladder.com/level/${baseData.level_id}`
                                        : ''
                                }
                                info="GDDL"
                            />
                        </LevelDataWrapper>

                        {creatorsIsLoading ? (
                            <Loader />
                        ) : creatorsIsError ? (
                            <ErrorComponent message="Creators failed to load" />
                        ) : !creatorsData ? (
                            <Loader />
                        ) : (
                            <ScrollWrapper
                                height={
                                    creatorsData?.length > 15 ? '30vh' : '20vh'
                                }
                            >
                                <LevelUsersWrapper>
                                    <span>
                                        {creatorsData?.length > 0
                                            ? 'Publisher'
                                            : 'Publisher \n& Creator'}
                                    </span>
                                    <UsersList users={[baseData?.publisher]} />
                                    <span>Verifier</span>
                                    <UsersList
                                        users={[
                                            baseData?.verification
                                                ?.submitted_by,
                                        ]}
                                    />
                                    {creatorsData?.length > 0 ? (
                                        <>
                                            <span>
                                                {creatorsData?.length == 1
                                                    ? 'Creator'
                                                    : 'Creators'}
                                            </span>
                                            <UsersList users={creatorsData} />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </LevelUsersWrapper>
                            </ScrollWrapper>
                        )}

                        <SubTitle>Position History</SubTitle>
                        <History />
                    </ScrollWrapper>
                </>
            )}
        </CardWrapper>
    );
}

export default LevelInfo;
