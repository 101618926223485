import { useListStore } from 'common/stores';
import { HistoryEntry as HistoryEntryType } from 'common/types/API';
import ScrollWrapper from 'components/Layout/ScrollWrapper';
import styled from 'styled-components';
import { useLevelHistory } from 'utils/publicQueries';
import { Loader } from 'components/Others/Loader';
import ErrorComponent from 'components/Others/Error';
import colors from 'utils/style/colors';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { LevelLink } from 'components/Others/LevelDisplay';

interface HistoryProps {
    entry: HistoryEntryType;
}

const StyledHistoryTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    align-self: center;
    margin: 0;
    padding: 0;
    text-align: left;
`;

const StyledHistoryRow = styled.tr`
    background: ${colors.confirmTransparent};
    border-bottom: 1px solid ${colors.primaryLight};
    &:last-child {
        border-bottom: none;
    }
    &.header {
        background: ${colors.backgroundLightTransparent};
        border-bottom: 1px solid ${colors.primaryLight};
    }

    &.negative {
        background: ${colors.cancelTransparent};
    }

    &.legacy {
        background: ${colors.backgroundLightTransparent};
    }
`;

const StyledHistoryCell = styled.td`
    padding: 0.5rem;

    &.position {
        text-align: center;
    }
    &.position-change {
    }
    &.cause {
        flex-grow: 1;
        text-align: left;

        a {
            font-weight: 700;
        }
    }
    &.date {
        text-align: right;
    }
`;

function HistoryEntry(props: HistoryProps) {
    const entry = props.entry;

    const dateStr = entry.action_at.split('T')[0];
    let cause = <></>;

    switch (entry.event) {
        case 'Placed':
            cause = <> Placed at #{entry.position}</>;
            break;
        case 'OtherPlaced':
            cause = (
                <>
                    <LevelLink level={entry.cause} />
                    was placed above
                </>
            );
            break;
        case 'MovedUp':
            cause = <> Moved up to #{entry.position}</>;
            break;
        case 'MovedDown':
            cause = (
                <>
                    {!entry.legacy
                        ? ` Moved down to #${entry.position}`
                        : ' Moved to legacy list'}
                </>
            );
            break;
        case 'OtherMovedUp':
            cause = (
                <>
                    <LevelLink level={entry.cause} />
                    was moved above
                </>
            );
            break;
        case 'OtherMovedDown':
            cause = (
                <>
                    <LevelLink level={entry.cause} />
                    was moved below
                </>
            );
            break;
        case 'OtherRemoved':
            cause = (
                <>
                    <LevelLink level={entry.cause} />
                    was removed from the list
                </>
            );
            break;
        default:
            cause = <> Unknown</>;
            break;
    }
    return (
        <StyledHistoryRow
            className={
                entry.legacy
                    ? 'legacy'
                    : entry.position_diff > 0
                      ? 'negative'
                      : ''
            }
        >
            <StyledHistoryCell className="position">
                {!entry.legacy ? entry.position : '-'}
            </StyledHistoryCell>
            <StyledHistoryCell className="position-change">
                {entry.event === 'Placed' ? (
                    ''
                ) : (
                    <>
                        {entry.position_diff < 0 ? (
                            <FaArrowUp />
                        ) : (
                            <FaArrowDown />
                        )}{' '}
                        {Math.abs(entry.position_diff)}
                    </>
                )}
            </StyledHistoryCell>
            <StyledHistoryCell className="cause">{cause}</StyledHistoryCell>
            <StyledHistoryCell className="date">{dateStr}</StyledHistoryCell>
        </StyledHistoryRow>
    );
}

function History() {
    const { listType, selectedLevel } = useListStore();
    const { isLoading, isError, data, error } = useLevelHistory(
        listType,
        selectedLevel,
    );

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : !data ? (
                <Loader />
            ) : isError ? (
                error instanceof Error ? (
                    <ErrorComponent message={error.message} />
                ) : (
                    ''
                )
            ) : (
                <ScrollWrapper height="30vh">
                    <StyledHistoryTable>
                        <thead>
                            <StyledHistoryRow className="header">
                                <StyledHistoryCell className="position">
                                    Position
                                </StyledHistoryCell>
                                <StyledHistoryCell className="position-change">
                                    Change
                                </StyledHistoryCell>
                                <StyledHistoryCell className="cause">
                                    Cause
                                </StyledHistoryCell>
                                <StyledHistoryCell className="date">
                                    Date
                                </StyledHistoryCell>
                            </StyledHistoryRow>
                        </thead>
                        <tbody>
                            {data.map((entry: HistoryEntryType) => (
                                <HistoryEntry
                                    entry={entry}
                                    key={`${entry.position}-${entry.event}-${entry.position_diff}-${entry.action_at}`}
                                />
                            ))}
                        </tbody>
                    </StyledHistoryTable>
                </ScrollWrapper>
            )}
        </>
    );
}

export default History;
