import { Color } from 'components/Others/Notification';
import { create } from 'zustand';
interface NotificationType {
    color: keyof typeof Color;
    id: number;
    text: string;
}

interface NotifyState {
    notifications: NotificationType[];
    createNotification: (color: keyof typeof Color, text: string) => void;
    deleteNotification: (id: number) => void;
}
const useNotifications = create<NotifyState>((set) => ({
    notifications: [],
    createNotification: (color: keyof typeof Color, text: string) => {
        set((state) => ({
            notifications: [
                ...state.notifications,
                { color, id: state.notifications.length, text },
            ],
        }));
    },
    deleteNotification: (id: number) => {
        set((state) => ({
            notifications: state.notifications.filter(
                (notification) => notification.id !== id,
            ),
        }));
    },
}));

export default useNotifications;
