import ErrorComponent from 'components/Others/Error';
import { Loader } from 'components/Others/Loader';
import { UserLink } from 'components/Others/UserDisplay';
import styled from 'styled-components';
import { useEditors } from 'utils/publicQueries';
import colors from 'utils/style/colors';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

const StyledEditors = styled.section`
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: ${colors.backgroundDarker};
    width: 100%;

    h2 {
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        color: ${colors.primary};
        text-align: center;
    }

    .editor-group {
        margin-bottom: 1rem;
        text-align: center;

        h3 {
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
            color: ${colors.primary};
        }

        .editor-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 0.5rem;

            .editor-item {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                background-color: ${colors.backgroundLight};
                padding: 0.25rem 0.5rem;
                border-radius: 0.5rem;
                font-size: 1rem;

                img {
                    width: 1rem;
                    height: 1rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }
`;

const StyledSupporters = styled.section`
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: ${colors.backgroundDarker};
    width: 100%;
    text-align: center;

    h2 {
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        color: ${colors.primary};
        text-align: center;
    }

    .supporters-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;

        .supporter-item {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            background-color: ${colors.backgroundLight};
            padding: 0.25rem 0.5rem;
            border-radius: 0.5rem;
            font-size: 1rem;

            img {
                width: 1rem;
                height: 1rem;
                margin-right: 0.5rem;
            }
        }
    }
`;

const roleOrder = ['owner', 'admin', 'developer', 'mod', 'helper', 'plus'];

const roleHeadings: { [key: string]: string } = {
    owner: 'Owners',
    admin: 'Admins',
    developer: 'Developers',
    mod: 'Moderators',
    helper: 'Helpers',
    plus: 'Supporters',
};

function Editors() {
    const {
        data: editorsData,
        isLoading: isEditorsLoading,
        isError: isEditorsError,
        error: editorsError,
    } = useEditors();

    const sortedEditorsData = editorsData
        ? editorsData.sort(
            (a, b) =>
                roleOrder.indexOf(a.role_desc) -
                roleOrder.indexOf(b.role_desc),
        )
        : [];

    return (
        <StyledContainer>
            <StyledEditors>
                <h2>LIST EDITORS</h2>
                {isEditorsLoading ? (
                    <Loader />
                ) : !editorsData ? (
                    <Loader />
                ) : isEditorsError ? (
                    editorsError instanceof Error ? (
                        <ErrorComponent message={editorsError.message} />
                    ) : (
                        ''
                    )
                ) : (
                    <>
                        {sortedEditorsData.map((role) =>
                            role.role_desc !== 'plus' ? (
                                <div className="editor-group" key={role.id}>
                                    <h3>{roleHeadings[role.role_desc]}</h3>
                                    <div className="editor-list">
                                        {role.users?.map((editor) => (
                                            <div
                                                className="editor-item"
                                                key={editor.id}
                                            >
                                                <img
                                                    src={`/assets/staff-icons/${role.role_desc}.svg`}
                                                    alt={role.role_desc}
                                                />
                                                <UserLink user={editor} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                        )}
                    </>
                )}
            </StyledEditors>

            {sortedEditorsData.some((role) => role.role_desc === 'plus') && (
                <StyledSupporters>
                    <h1>Supporters</h1>
                    <div className="supporters-list">
                        {sortedEditorsData
                            .find((role) => role.role_desc === 'plus')
                            ?.users.map((supporter) => (
                                <div
                                    className="supporter-item"
                                    key={supporter.id}
                                >
                                    <img
                                        src={`/assets/staff-icons/plus.svg`}
                                        alt="Supporter"
                                    />
                                    <UserLink user={supporter} />
                                </div>
                            ))}
                    </div>
                </StyledSupporters>
            )}
        </StyledContainer>
    );
}

export default Editors;
