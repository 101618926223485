import { LevelPack } from 'common/types/API';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { Link } from 'react-router-dom';

interface PacksProps {
    packs: LevelPack[];
}

interface PackProps {
    $pack: LevelPack;
}

interface TagsProps {
    tags: string[];
}

const StyledPacksWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: flex-start;
    height: fit-content;
    margin-bottom: 0.25rem;
    gap: 0.25rem;
`;

const StyledTagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: flex-start;
    height: fit-content;
    margin: 0.25rem 0 2rem 0;
    gap: 0.25rem;

    span {
        font-size: 1rem;
        padding: 0.1rem 0.5rem;
        color: ${colors.primary};
        border-radius: 0.5rem;
        border: 0.1rem solid ${colors.primaryLight};
        background: ${colors.backgroundLightTransparent};
    }
`;

const StyledPack = styled.span<PackProps>`
    background: ${(props) => props.$pack.tier.color};
    color: ${colors.primary};
    text-shadow: 0.1rem 0.1rem 0.2rem black;
    padding: 0.5rem;
    border-radius: 0.5rem;

    &:hover {
        transition: opacity 0.5s;
        opacity: 70%;
    }
`;

export function PacksWrapper(props: PacksProps) {
    return (
        <StyledPacksWrapper>
            {props.packs.map((pack, index) => (
                <StyledPack key={`${pack.name}-${index}`} $pack={pack}>
                    <Link to={`/packs/${pack.id}`}>{pack.name}</Link>
                </StyledPack>
            ))}
        </StyledPacksWrapper>
    );
}

export function TagsWrapper(props: TagsProps) {
    return (
        <StyledTagsWrapper>
            {props.tags.map((tag, index) => (
                <span key={`${tag}-${index}`}>{tag}</span>
            ))}
        </StyledTagsWrapper>
    );
}
