import GlobalStyle from 'utils/style/globalStyle';
import { useBackground } from 'common/stores';

const GlobalStyleWrapper = () => {
    const { background, opacity, grayscale } = useBackground();
    return (
        <GlobalStyle
            background={background}
            bgopacity={opacity}
            grayscale={grayscale}
        />
    );
};

export default GlobalStyleWrapper;
