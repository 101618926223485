import { create } from 'zustand';

interface LeaderboardState {
    leaderboardType: string;
    sort: string;
    country: string;
    page: number;
    listType: string;
    search: string;
    setLeaderboardType: (value: string) => void;
    setSort: (value: string) => void;
    setCountry: (value: string) => void;
    setListType: (value: string) => void;
    setPage: (value: number) => void;
    setSearch: (value: string) => void;
}

const useLeaderboardStore = create<LeaderboardState>((set) => ({
    leaderboardType: 'players',
    country: '0',
    sort: 'total points',
    page: 1,
    listType: 'classic',
    search: '',
    setLeaderboardType: (value) =>
        set((state) => ({ ...state, leaderboardType: value })),
    setSort: (value) => set((state) => ({ ...state, sort: value })),
    setCountry: (value) => set((state) => ({ ...state, country: value })),
    setPage: (value) => set((state) => ({ ...state, page: value })),
    setListType: (value) => set((state) => ({ ...state, listType: value })),
    setSearch: (value) => set((state) => ({ ...state, search: value })),
}));

export default useLeaderboardStore;
