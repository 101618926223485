import useProfileStore from 'common/stores/profile';
import ValueSwitch from 'components/Layout/ValueSwitch';
import ReturnButton from 'components/Others/ReturnButton';
import styled from 'styled-components';

const SyledProfiledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 5vh;
    margin: 1rem 0;

    h3 {
        font-size: 3rem;
        margin: 0 5%;
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        height: 100%;
        h3 {
            margin: 0;
            text-align: center;
            width: 100%;
        }
    }
`;

const ProfileHeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
    width: 40%;
    align-items: center;
    @media (max-width: 1200px) {
        width: 60%;
        flex-direction: column;
    }
`;

const ProfileHeaderRight = styled.div`
    margin: 0 3rem;
    @media (max-width: 1200px) {
        display: none;
    }
`;

function ProfileHeader() {
    const { listType, setListType } = useProfileStore();
    return (
        <SyledProfiledHeader>
            <ProfileHeaderLeft>
                <h3>PROFILE</h3>
                <ValueSwitch
                    options={['classic', 'platformer']}
                    current={listType}
                    set={setListType}
                />
            </ProfileHeaderLeft>
            <ProfileHeaderRight>
                <ReturnButton />
            </ProfileHeaderRight>
        </SyledProfiledHeader>
    );
}

export default ProfileHeader;
