import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import Navbar from './Navbar';
import StyledButton from './Button';
import useAuth from 'common/stores/auth';
import { Dropdown, DropdownItem } from './Dropdown';
import { useNotifications } from 'common/stores';
import { FaDiscord } from 'react-icons/fa6';

const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid ${colors.primaryLight};

    @media (max-width: 1000px) {
        align-items: flex-start;
    }
`;

const StyledLogo = styled.img`
    width: 100%;
    max-width: 150px;
    height: auto;
    object-fit: cover;
`;

const HeaderRightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    svg {
        height: 2rem;
        width: 2rem;
        padding: 0.5rem;
        background-color: ${colors.secondary};
        border-radius: 50%;
        color: ${colors.primary};
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (max-width: 1000px) {
        display: none;
    }
`;

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledIcon = styled.img`
    width: 3rem;
    height: 3rem;
    vertical-align: middle;
    cursor: pointer;
    margin-left: auto;
    transition: transform 0.5s;
    border-radius: 50%;

    &:hover {
        transform: scale(1.1);
    }
`;

function Header() {
    const { isAuthenticated, user, login, logout } = useAuth();
    const navigate = useNavigate();
    const { createNotification } = useNotifications();

    const avatarUrl =
        user != null && user?.discord_avatar
            ? `https://cdn.discordapp.com/avatars/${user.discord_id}/${user.discord_avatar}.` +
              (user?.discord_avatar?.startsWith('a_') ? 'gif' : 'png')
            : '/assets/avatar_default.svg';
    return (
        <StyledHeader>
            <Link to="/">
                <StyledLogo src="/assets/logo.png" alt="AREDL_logo" />
            </Link>

            <Navbar />

            <HeaderRightContainer>
                <FaDiscord
                    onClick={() =>
                        window.open('https://discord.gg/aredl', '_blank')
                    }
                />

                <ProfileContainer>
                    {isAuthenticated ? (
                        <Dropdown
                            buttonComponent={
                                <StyledIcon src={avatarUrl} alt="Avatar" />
                            }
                            content={
                                <>
                                    <DropdownItem
                                        onClick={() =>
                                            navigate(
                                                `/profile/${user?.id ?? ''}`,
                                            )
                                        }
                                    >
                                        Profile
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => navigate('/submissions')}
                                    >
                                        Submit a record
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => navigate('/settings')}
                                    >
                                        Settings
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            logout(createNotification);
                                        }}
                                    >
                                        Logout
                                    </DropdownItem>
                                </>
                            }
                            $alignright={true}
                        />
                    ) : (
                        <StyledButton
                            onClick={() => {
                                login(createNotification);
                            }}
                        >
                            LOGIN
                        </StyledButton>
                    )}
                </ProfileContainer>
            </HeaderRightContainer>
        </StyledHeader>
    );
}

export default Header;
