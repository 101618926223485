import PacksList from 'components/Packs/PacksList';
import styled from 'styled-components';

const PacksPageWrapper = styled.main`
    display: grid;
    height: fit-content;
    align-self: center;
    width: 100%;
    grid-template-columns: 2fr 3fr;
    grid-gap: 1rem;

    @media (max-width: 1000px) {
        margin: 1rem auto;
        grid-template-columns: 1fr;

        width: 90%;
    }

    section {
        display: flex;

        @media (max-width: 1000px) {
            display: none;

            &.active {
                display: flex;
            }
        }
    }
`;
function Packs() {
    return (
        <PacksPageWrapper>
            <PacksList />
        </PacksPageWrapper>
    );
}

export default Packs;
