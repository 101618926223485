import { useLeaderboard } from 'utils/publicQueries';
import { Loader } from 'components/Others/Loader';
import ErrorComponent from 'components/Others/Error';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { LeaderboardEntry as LeaderboardEntryType } from 'common/types/API';
import { UserLink } from 'components/Others/UserDisplay';
import { useCountryStore, useLeaderboardStore } from 'common/stores';
import { LevelLink } from 'components/Others/LevelDisplay';

interface LeaderboardProps {
    entry: LeaderboardEntryType;
}

const StyledLeaderboardTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    align-self: center;
    margin: 0;
    padding: 0;
    text-align: center;
`;

const StyledLeaderboardRow = styled.tr`
    color: ${colors.primary};
    border-bottom: 1px solid ${colors.primaryLight};
    height: 3rem;

    &:last-child {
        border-bottom: none;
    }
    &.header {
        background: ${colors.secondary};
        border-bottom: 1px solid ${colors.primaryLight};
        text-transform: uppercase;
        height: 3rem;
    }
`;

const StyledProfileCell = styled.div`
    align-items: center;
    display: flex;
    margin-left: 20%;
    overflow-x: hidden;

    img {
        height: 2rem;
    }

    @media (max-width: 1000px) {
        margin-left: 0;
    }
`;

const StyledLeaderboardCell = styled.td`
    white-space: nowrap;
    vertical-align: middle;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &.discorduser {
        @media (max-width: 1600px) {
            display: none;
        }
    }

    &.levelsbeaten,
    &.hardest,
    &.countryrank {
        @media (max-width: 1200px) {
            display: none;
        }
    }

    &.country {
        img {
            height: 1.5rem;
            vertical-align: middle;
        }
        @media (max-width: 1000px) {
            display: none;
        }
    }
`;

function LeaderboardEntry(props: LeaderboardProps) {
    const user = props.entry.user;
    const country = props.entry.country;
    const { getCountryByNumber } = useCountryStore();
    const { sort, country: selectedCountry } = useLeaderboardStore();
    const avatarUrl =
        user != null && user?.discord_avatar
            ? `https://cdn.discordapp.com/avatars/${user.discord_id}/${user.discord_avatar}.` +
              (user?.discord_avatar?.startsWith('a_') ? 'gif' : 'png')
            : '/assets/avatar_default.svg';

    return (
        <StyledLeaderboardRow>
            <StyledLeaderboardCell className="user">
                <StyledProfileCell>
                    <img src={avatarUrl} />
                    <UserLink user={user} />
                </StyledProfileCell>
            </StyledLeaderboardCell>
            <StyledLeaderboardCell className="country">
                {country ? (
                    <img
                        src={`/assets/flags/${getCountryByNumber(country)?.alpha2}.svg`}
                    />
                ) : (
                    '-'
                )}
            </StyledLeaderboardCell>
            <StyledLeaderboardCell className="rank">
                {sort === 'total points'
                    ? selectedCountry === '0'
                        ? props.entry.rank
                        : props.entry.country_rank
                    : sort === 'level points only'
                      ? selectedCountry === '0'
                          ? props.entry.raw_rank
                          : props.entry.country_raw_rank
                      : sort === 'extremes count'
                        ? selectedCountry === '0'
                            ? props.entry.extremes_rank
                            : props.entry.country_extremes_rank
                        : '-'}
            </StyledLeaderboardCell>
            <StyledLeaderboardCell className="points">
                {sort === 'level points only'
                    ? (props.entry.total_points - props.entry.pack_points) / 10
                    : props.entry.total_points / 10}
            </StyledLeaderboardCell>
            <StyledLeaderboardCell className="levelsbeaten">
                {props.entry.extremes ?? '-'}
            </StyledLeaderboardCell>
            <StyledLeaderboardCell className="hardest">
                {props.entry.hardest ? (
                    <LevelLink level={props.entry.hardest} />
                ) : (
                    '-'
                )}
            </StyledLeaderboardCell>
            <StyledLeaderboardCell className="discorduser">
                {user.username ?? '-'}
            </StyledLeaderboardCell>
        </StyledLeaderboardRow>
    );
}

function LeaderboardTable() {
    const { data, isLoading, isError, error } = useLeaderboard();
    const { sort, country } = useLeaderboardStore();
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : !data ? (
                <Loader />
            ) : isError ? (
                error instanceof Error ? (
                    <ErrorComponent message={error.message} />
                ) : (
                    ''
                )
            ) : (
                <>
                    <StyledLeaderboardTable>
                        <thead>
                            <StyledLeaderboardRow className="header">
                                <StyledLeaderboardCell>
                                    User
                                </StyledLeaderboardCell>
                                <StyledLeaderboardCell className="country">
                                    Country
                                </StyledLeaderboardCell>
                                <StyledLeaderboardCell>
                                    {country === '0'
                                        ? 'global rank'
                                        : 'country rank'}
                                </StyledLeaderboardCell>
                                <StyledLeaderboardCell>
                                    {sort === 'level points only'
                                        ? 'Level Points'
                                        : 'Total Points'}
                                </StyledLeaderboardCell>
                                <StyledLeaderboardCell className="levelsbeaten">
                                    Extremes beaten
                                </StyledLeaderboardCell>
                                <StyledLeaderboardCell className="hardest">
                                    Hardest level
                                </StyledLeaderboardCell>
                                <StyledLeaderboardCell className="discorduser">
                                    Discord username
                                </StyledLeaderboardCell>
                            </StyledLeaderboardRow>
                        </thead>
                        <tbody>
                            {data.data.map((leaderboardEntry, index) => (
                                <LeaderboardEntry
                                    key={`leaderboard-entry-${index}`}
                                    entry={leaderboardEntry}
                                />
                            ))}
                        </tbody>
                    </StyledLeaderboardTable>
                    {data?.data.length === 0 ? <h3>No results</h3> : ''}
                </>
            )}
        </>
    );
}

export default LeaderboardTable;
