import { create } from 'zustand';

interface ProfileState {
    listType: string;
    levelDisplayType: string;
    selectedUser: string;
    setListType: (value: string) => void;
    setSelectedUser: (value: string) => void;
    setLevelDisplayType: (value: string) => void;
}

const useProfileStore = create<ProfileState>((set) => ({
    listType: 'classic',
    levelDisplayType: 'completed',
    selectedUser: '',
    setListType: (value) => set((state) => ({ ...state, listType: value })),
    setLevelDisplayType: (value) =>
        set((state) => ({ ...state, levelDisplayType: value })),
    setSelectedUser: (value) =>
        set((state) => ({ ...state, selectedUser: value })),
}));

export default useProfileStore;
