import { Level } from 'common/types/API';
import StyledLink from './StyledLink';

interface LevelProps {
    level: Level;
    className?: string;
}

export function LevelLink(props: LevelProps) {
    if (props.level === null) {
        console.warn('LevelLink: passed level is null');
        return <>NULL LEVEL</>;
    } else {
        return (
            <StyledLink
                to={`/list/${props.level.id}`}
                className={props.className}
            >
                {props.level.name}
            </StyledLink>
        );
    }
}
