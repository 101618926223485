import React, {
    useEffect,
    useState,
    useRef,
    forwardRef,
    RefObject,
    ReactNode,
} from 'react';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import colors from 'utils/style/colors';

interface DropdownButtonProps {
    children: React.ReactNode;
    toggle: () => void;
    open: boolean;
    custom: boolean;
}

interface DropdownContentProps {
    children: React.ReactNode;
    open: boolean;
    top: number | null;
    $alignright: boolean;
    buttonRect?: DOMRect | null;
}

interface DropdownItemProps {
    children: React.ReactNode;
    onClick: () => void;
    $closedropdown?: () => void;
}

interface DropdownProps {
    buttonText?: string;
    buttonComponent?: React.ReactNode;
    content: React.ReactNode;
    $alignright?: boolean;
}

const StyledDropdown = styled.div`
    position: relative;
    text-transform: uppercase;
`;

const StyledDropdownBtn = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    color: ${colors.primary};
    padding: 1rem;
    background-color: ${colors.backgroundSemiLight};
    border-radius: 0.5rem;
    box-shadow: ${colors.backgroundDark} 0px 8px 24px;
    cursor: pointer;

    .button-open {
        border: rgb(147 197 253) 2px solid;
    }

    .toggle-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
    }
`;

const StyledDropdownContent = styled.div<{ $alignright: boolean }>`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    margin-top: 0.5rem;
    background-color: ${colors.backgroundSemiLight};
    border-radius: 0.5rem;
    box-shadow: ${colors.backgroundDark} 0px 8px 24px;
    max-height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    scrollbar-width: thin;
    -ms-overflow-style: auto;

    transition:
        transform 150ms ease-in-out,
        opacity 100ms ease-in-out;
    opacity: 0;
    pointer-events: none;
    left: ${({ $alignright }) => ($alignright ? 'auto' : '0')};
    right: ${({ $alignright }) => ($alignright ? '0' : 'auto')};

    &&::-webkit-scrollbar {
        width: 8px;
    }

    &.content-open {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    }

    @media (max-width: 1000px) {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        width: 80%;
    }
`;

const StyledDropdownItem = styled.div`
    padding: 0.5rem;
    margin: 0.1rem;
    text-align: left;
    width: 100%;
    min-width: 10vw;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
        background-color: ${colors.primaryLight};
        color: ${colors.primary};
    }
`;

const DropdownButton = forwardRef<HTMLDivElement, DropdownButtonProps>(
    ({ children, toggle, open, custom }, ref) => (
        <>
            {custom ? (
                <div onClick={toggle} ref={ref as RefObject<HTMLDivElement>}>
                    {children}
                </div>
            ) : (
                <StyledDropdownBtn
                    onClick={toggle}
                    className={`${open ? 'button-open' : ''}`}
                    ref={ref as RefObject<HTMLDivElement>}
                >
                    {children}
                    <span className="toggle-icon">
                        {open ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                </StyledDropdownBtn>
            )}
        </>
    ),
);

const DropdownContent = forwardRef<HTMLDivElement, DropdownContentProps>(
    ({ children, open, top, $alignright, buttonRect }, ref) => {
        const isMobile = window.innerWidth <= 1000;

        return (
            <StyledDropdownContent
                className={`${open ? 'content-open' : ''}`}
                style={{
                    top: isMobile ? `${buttonRect?.bottom}px` : `${top}px`,
                    left: isMobile
                        ? '50%'
                        : $alignright
                          ? 'auto'
                          : `${buttonRect?.left}px`,
                    right: isMobile
                        ? 'auto'
                        : $alignright
                          ? `${window.innerWidth - (buttonRect?.right || 0)}px`
                          : 'auto',
                    transform: isMobile ? 'translateX(-50%)' : 'none',
                    width: isMobile ? '80%' : 'auto',
                }}
                ref={ref as RefObject<HTMLDivElement>}
                $alignright={$alignright}
            >
                {children}
            </StyledDropdownContent>
        );
    },
);

const DropdownItem = ({
    children,
    onClick,
    $closedropdown,
}: DropdownItemProps) => (
    <StyledDropdownItem
        onClick={() => {
            onClick();
            if ($closedropdown) $closedropdown();
        }}
    >
        {children}
    </StyledDropdownItem>
);

const addPropsToChildren = (children: ReactNode, props: object): ReactNode => {
    return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child;
        if (child.type === React.Fragment) {
            return React.cloneElement(
                child,
                {},
                addPropsToChildren(child.props.children, props),
            );
        }
        return React.cloneElement(child, props);
    });
};
const Dropdown = ({
    buttonText,
    buttonComponent,
    content,
    $alignright = false,
}: DropdownProps) => {
    const [open, setOpen] = useState(false);
    const [dropdownTop, setDropdownTop] = useState<number | null>(0);
    const [buttonRect, setButtonRect] = useState<DOMRect | null>(null);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        if (!open) {
            const rect = buttonRef.current?.getBoundingClientRect() || null;
            setButtonRect(rect);

            if (rect) {
                const spaceRemaining = window.innerHeight - (rect?.bottom || 0);
                const contentHeight = contentRef.current?.clientHeight || 0;

                const topPosition =
                    spaceRemaining > contentHeight
                        ? rect?.bottom
                        : (rect?.top || 0) - contentHeight;

                if (window.innerWidth <= 1000) {
                    setDropdownTop(rect?.bottom || 0);
                } else {
                    setDropdownTop(topPosition);
                }
            } else setDropdownTop(null);
        }

        setOpen((open) => !open);
    };

    useEffect(() => {
        const handler = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpen(false);
            }
        };

        document.addEventListener('click', handler);

        return () => {
            document.removeEventListener('click', handler);
        };
    }, [dropdownRef]);

    return (
        <StyledDropdown ref={dropdownRef}>
            <DropdownButton
                ref={buttonRef}
                toggle={toggleDropdown}
                open={open}
                custom={!buttonText}
            >
                {buttonText || buttonComponent}
            </DropdownButton>
            <DropdownContent
                top={dropdownTop}
                ref={contentRef}
                open={open}
                buttonRect={buttonRect}
                $alignright={$alignright}
            >
                {addPropsToChildren(content, {
                    $closedropdown: () => setOpen(false),
                })}
            </DropdownContent>
        </StyledDropdown>
    );
};

DropdownButton.displayName = 'DropdownButton';
DropdownButton.propTypes = {
    children: PropTypes.node.isRequired,
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    custom: PropTypes.bool.isRequired,
};

DropdownContent.displayName = 'DropdownContent';
DropdownContent.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    top: PropTypes.number,
    $alignright: PropTypes.bool.isRequired,
};

DropdownItem.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    $closedropdown: PropTypes.func,
};

Dropdown.propTypes = {
    buttonText: PropTypes.string,
    buttonComponent: PropTypes.node,
    content: PropTypes.node.isRequired,
    $alignright: PropTypes.bool,
};

export { Dropdown, DropdownItem };
