import { PackTier } from 'common/types/API';
import { create } from 'zustand';

interface PackState {
    selectedPack: string;
    selectPack: (id: string, tiers: PackTier[]) => boolean;
}

const usePackStore = create<PackState>((set) => ({
    selectedPack: '',
    selectPack: (id: string, tiers: PackTier[]) => {
        for (const tier of tiers) {
            const pack = tier.packs.find((pack) => pack.id === id);
            if (pack) {
                set({
                    selectedPack: pack.id,
                });
                return true;
            }
        }
        return false;
    },
}));

export default usePackStore;
