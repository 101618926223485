import styled from 'styled-components';
import colors from 'utils/style/colors';

interface ErrorProps {
    code?: string;
    message: string;
}

const StyledError = styled.div`
    display: flex;
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: row;
    border: 0;
    h1 {
        font-size: 3rem;
        width: fit-content;
        padding: 0.5rem;
        border-bottom: 0;
    }
    p {
        border-left: ${colors.primaryLight} solid 0.1rem;
        display: block;

        padding: 0.5rem;
        color: ${colors.primary};
    }
`;
function Error(props: ErrorProps) {
    return (
        <StyledError>
            <h1>{props.code ?? '500'}</h1>
            <p>{props.message}</p>
        </StyledError>
    );
}

export default Error;
