import { useBackground } from 'common/stores';
import useProfileStore from 'common/stores/profile';
import CardWrapper from 'components/Layout/CardWrapper';
import ProfileHeader from 'components/Profile/ProfileHeader';
import ProfileMain from 'components/Profile/ProfileMain';
import ProfileSummary from 'components/Profile/ProfileSummary';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useProfile } from 'utils/publicQueries';

const ProfileWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 73vh;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`;

function Profile() {
    const { userid } = useParams();
    const { setSelectedUser } = useProfileStore();
    const { setBackground, resetBackground } = useBackground();
    const { data, isLoading, isError } = useProfile();

    useEffect(() => {
        if (userid) {
            setSelectedUser(userid);
        }
    }, [userid]);

    useEffect(() => {
        if (
            !isLoading &&
            !isError &&
            data?.records &&
            data.records[0]?.level?.level_id
        ) {
            setBackground(
                `https://raw.githubusercontent.com/cdc-sys/level-thumbnails/main/thumbs/${data.records[0].level.level_id}.png`,
                '0.05',
                '100%',
            );
        }
    }, [data]);

    useEffect(() => {
        return resetBackground;
    }, []);

    return (
        <main>
            <CardWrapper height="85vh">
                <ProfileHeader />
                <ProfileWrapper>
                    <ProfileSummary />
                    <ProfileMain />
                </ProfileWrapper>
            </CardWrapper>
        </main>
    );
}

export default Profile;
