import { create } from 'zustand';

interface Country {
    alpha2: string;
    name: string;
}

interface CountryState {
    countriesMap: Record<string, Country>;
    availableCountries: [string, string][];
    fetchCountries: () => void;
    getCountryByNumber: (number: string | number) => Country | undefined;
    getAvailableCountries: () => [string, string][];
}

const useCountryStore = create<CountryState>((set, get) => ({
    countriesMap: {},
    availableCountries: [],
    fetchCountries: async () => {
        try {
            const response = await fetch('/assets/flags/_map.json');
            const data = await response.json();

            const availableCountries: [string, string][] = Object.keys(data)
                .map((country: string): [string, string] => [
                    country,
                    data[country].name,
                ])
                .sort((a, b) => a[1].localeCompare(b[1]));
            set({
                countriesMap: data,
                availableCountries: availableCountries,
            });
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    },
    getCountryByNumber: (number) =>
        get().countriesMap[String(number).padStart(3, '0')],
    getAvailableCountries: () => get().availableCountries,
}));

export default useCountryStore;
