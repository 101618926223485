import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'utils/style/colors';

const StyledLink = styled(Link)`
    height: fit-content;
    width: fit-content;
    padding: 0.25rem;
    border-radius: 0.25rem;

    &:hover {
        color: ${colors.primary};
        background-color: ${colors.primaryLight};
        transition: background-color 0.25s;
    }
`;

export default StyledLink;
