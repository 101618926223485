/* eslint-disable react/prop-types */

import Modal from 'components/Layout/Modal';
import styled from 'styled-components';
import { useListStore } from 'common/stores';
import { BaseLevel } from 'common/types/API';
import colors from 'utils/style/colors';
import { useEffect, useState } from 'react';
import RadioGroup from 'components/Layout/RadioGroup';
import ItemSelector from 'components/Layout/ItemSelector';

interface RangeFilterInputProps {
    title: string;
    property: keyof BaseLevel;
}

const FilterWrapper = styled.div`
    display: block;

    margin-bottom: 1rem;

    h3 {
        font-size: 1.15rem;
        margin: 0;
        font-weight: 600;
    }
`;

const RangeInput = styled.input`
    width: 3rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    text-align: center;
    border: none;
    border-bottom: ${colors.primaryLight} solid 0.1rem;
    background-color: transparent;
    color: ${colors.primary};

    &:focus {
        outline: inherit;
        border-bottom: ${colors.secondary} solid 0.1rem;
    }
`;

const RangeFilterInput: React.FC<RangeFilterInputProps> = ({
    title,
    property,
}) => {
    const { filters, setRange } = useListStore();
    const rangeFilter = filters.ranges.find(
        (range) => range.property === property,
    );

    const [minValue, setMinValue] = useState<string>(
        rangeFilter?.range.min !== undefined
            ? String(rangeFilter.range.min)
            : '',
    );
    const [maxValue, setMaxValue] = useState<string>(
        rangeFilter?.range.max !== undefined
            ? String(rangeFilter.range.max)
            : '',
    );

    useEffect(() => {
        const min = minValue !== '' ? parseFloat(minValue) : undefined;
        const max = maxValue !== '' ? parseFloat(maxValue) : undefined;
        setRange(property, min, max);
    }, [minValue, maxValue]);

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value) || value === '') {
            setMinValue(value);
        }
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value) || value === '') {
            setMaxValue(value);
        }
    };

    return (
        <FilterWrapper>
            <h3>{title}:</h3>
            From
            <RangeInput
                key="min"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                value={minValue === undefined ? '' : minValue}
                onChange={handleMinChange}
            />
            to
            <RangeInput
                key="max"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                value={maxValue === undefined ? '' : maxValue}
                onChange={handleMaxChange}
            />
        </FilterWrapper>
    );
};

const SettingsSection = styled.div`
    margin: 1rem 0;
    max-width: 20vw;
    @media (max-width: 1000px) {
        max-width: 100%;
    }
`;

const ModalContainer = styled.div`
    min-width: 35vw;
    min-height: 35vh;

    h1 {
        padding: 0;
        margin: 0;
        font-size: 1.75rem;
        border: none;
    }

    h2 {
        padding: 0;
        margin: 0 0 1rem 0;
        font-size: 1.5rem;
    }
    @media (max-width: 1000px) {
        width: 80vw;
        height: 50vh;
    }
`;

const SettingsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    width: 100%;
    height: 100%;
    gap: 2rem;
`;

interface ListModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const availableTags = [
    '2.2',
    '2.1',
    '2.0',
    '1.9PS',
    '1.9',
    '1.8',
    '1.6PS',
    '1.6',
    '1.5',
    'Medium',
    'Long',
    'XL',
    'XXL',
    'XXXL',
    'XXXXL',
    'XXXXXL',
    'XXXXXXL',
    'XXXXXXXL',
    'XXXXXXXXL',
    'XXL+',
    'Eon',
    'NONG',
    '2P',
    'Circles',
    'Clicksync',
    'Fast-paced',
    'Timings',
    'Chokepoints',
    'Learny',
    'Memory',
    'High CPS',
    'Gimmicky',
    'Flow',
    'Slow-Paced',
    'Precision',
    'Bossfight',
    'Mirror',
    'Nerve Control',
    'Cube',
    'Ship',
    'Ball',
    'UFO',
    'Wave',
    'Robot',
    'Spider',
    'Old Swing',
    'New Swing',
    'Duals',
    'Overall',
];

const ListSettingsModal: React.FC<ListModalProps> = ({ isOpen, onClose }) => {
    const { sort, setSort, filters, setTags } = useListStore();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalContainer>
                <h1>List search settings</h1>
                <SettingsContainer>
                    <SettingsSection>
                        <h2>Sort type</h2>
                        <RadioGroup
                            name="sort"
                            options={[
                                { label: 'AREDL Rank', value: 'rank' },
                                { label: 'EDEL Enjoyment', value: 'enjoyment' },
                            ]}
                            selectedValue={sort}
                            onChange={setSort}
                        />
                    </SettingsSection>
                    <SettingsSection>
                        <h2>Filters</h2>
                        <RangeFilterInput
                            title="AREDL rank"
                            property="position"
                        />
                        <RangeFilterInput
                            title="EDEL Enjoyment"
                            property="edel_enjoyment"
                        />
                        <RangeFilterInput
                            title="GDDL Tier"
                            property="gddl_tier"
                        />
                    </SettingsSection>
                    <SettingsSection>
                        <h2>Tags</h2>
                        <ItemSelector
                            availableItems={availableTags}
                            itemStates={filters.tags}
                            setItemStates={setTags}
                            allItemsDisplayed={true}
                        />
                    </SettingsSection>
                </SettingsContainer>
            </ModalContainer>
        </Modal>
    );
};

export default ListSettingsModal;
