import { useAuth } from './stores';

const backend = {
    baseUrl: 'https://api.aredl.net/v2/api',
    //baseUrl: 'http://127.0.0.1:5000/api',

    send: async function (
        path: string,
        method: string,
        query?: object,
        body?: object,
    ) {
        let cleanedQuery;
        if (query) {
            cleanedQuery = Object.entries(query)
                .filter((elt) => elt[1] !== null && elt[1] !== undefined)
                .map(([key, value]) => [key, String(value)]);
        }
        const url =
            this.baseUrl +
            path +
            (query ? '?' + new URLSearchParams(cleanedQuery) : '');

        let response;

        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
        };

        const token = useAuth.getState().token;
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        try {
            response = await fetch(url, {
                method: method,
                mode: 'cors',
                headers: headers,
                body: JSON.stringify(body),
            });
            return {
                status: response.status,
                data: await response.json(),
                error: !response.ok,
            };
        } catch (error) {
            console.error(
                `An unexpected error occurred: ${(error as Error)?.message ?? ''}`,
            );
            return {
                status: 500,
                data: {
                    message: 'An unexpected error occurred.',
                },
                error: true,
            };
        }
    },
};

export default backend;
