import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useState } from 'react';
import { Dropdown, DropdownItem } from 'components/Layout/Dropdown';
import colors from 'utils/style/colors';
import ScrollWrapper from './ScrollWrapper';
import { FaPlus } from 'react-icons/fa6';
import Search from 'components/Others/Search';

const Item = styled.div<{ $state: boolean; $enabled: boolean }>`
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${({ $state, $enabled }) =>
        !$enabled
            ? colors.primaryLight
            : $state
              ? colors.confirmTransparent
              : colors.cancelTransparent};
    color: ${colors.primary};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const SelectorWrapper = styled.div`
    background: ${colors.backgroundDarker};
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;

    .addtag {
        cursor: pointer;
        background: ${colors.backgroundLight};
        transition: background 0.3s;
        &:hover {
            background: ${colors.primaryLight};
        }
        border: 0.1rem solid ${colors.primaryLight};
        border-radius: 0.25rem;
        padding: 0.25rem;
        vertical-align: middle;
    }
`;

interface ItemSelectorProps {
    availableItems: string[];
    itemStates: { [key: string]: boolean };
    setItemStates: (itemStates: { [key: string]: boolean }) => void;
    allItemsDisplayed?: boolean;
}

const ItemSelector: React.FC<ItemSelectorProps> = ({
    availableItems,
    itemStates,
    setItemStates,
    allItemsDisplayed = false,
}) => {
    const [search, setSearch] = useState<string>('');

    const setItem = (item: string, state?: boolean) => {
        const updatedItemStates = { ...itemStates };

        if (state === undefined) delete updatedItemStates[item];
        else updatedItemStates[item] = state;

        setItemStates(updatedItemStates);
    };

    const handleItemClick = (item: string) => {
        const value =
            itemStates[item] === undefined
                ? true
                : itemStates[item]
                  ? false
                  : undefined;
        console.log(value);
        setItem(item, value);
    };

    const handleAddItem = (item: string) => {
        if (!(item in itemStates)) {
            setItem(item, true);
        }
    };

    const sortedItems = allItemsDisplayed
        ? [
              ...Object.keys(itemStates).sort(
                  (a, b) => (itemStates[b] ? 1 : -1) - (itemStates[a] ? 1 : -1),
              ),
              ...availableItems.filter((item) => !(item in itemStates)),
          ]
        : Object.keys(itemStates);

    return (
        <SelectorWrapper>
            <ScrollWrapper height="35vh">
                <ItemContainer>
                    {sortedItems.map((item) => (
                        <Item
                            key={item}
                            $state={itemStates[item] || false}
                            $enabled={itemStates[item] !== undefined}
                            onClick={() => handleItemClick(item)}
                        >
                            {item}
                        </Item>
                    ))}
                    {!allItemsDisplayed && (
                        <Dropdown
                            buttonComponent={<FaPlus className="addtag" />}
                            content={
                                <>
                                    <Search
                                        input={search}
                                        setinput={setSearch}
                                    />
                                    {availableItems
                                        .filter(
                                            (item) =>
                                                !Object.keys(
                                                    itemStates,
                                                ).includes(item),
                                        )
                                        .map((item) => (
                                            <DropdownItem
                                                key={item}
                                                onClick={() =>
                                                    handleAddItem(item)
                                                }
                                            >
                                                {item}
                                            </DropdownItem>
                                        ))}
                                </>
                            }
                        />
                    )}
                </ItemContainer>
            </ScrollWrapper>
        </SelectorWrapper>
    );
};

ItemSelector.propTypes = {
    availableItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    itemStates: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
    setItemStates: PropTypes.func.isRequired,
    allItemsDisplayed: PropTypes.bool,
};

export default ItemSelector;
