import React, { useEffect } from 'react';
import { useAuth } from 'common/stores';

const AuthRefresher: React.FC = () => {
    const updateAuthedUser = useAuth((state) => state.updateAuthedUser);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const refreshAuth = () => {
            updateAuthedUser();
            timeoutId = setTimeout(refreshAuth, 5 * 60 * 1000);
        };

        refreshAuth();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [updateAuthedUser]);

    return null;
};

export default AuthRefresher;
