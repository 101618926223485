/* eslint-disable react/prop-types */
import { useCountryStore, useLeaderboardStore } from 'common/stores';
import { Dropdown, DropdownItem } from 'components/Layout/Dropdown';
import Modal from 'components/Layout/Modal';
import Search from 'components/Others/Search';
import { useState } from 'react';
import styled from 'styled-components';

interface LeaderboardSettingsModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ModalContainer = styled.div`
    width: 35vw;
    min-height: 75vh;

    h1 {
        padding: 0;
        font-size: 1.5rem;
        border: none;
    }
    h2 {
        font-size: 1.2rem;
    }
    @media (max-width: 1000px) {
        width: 80vw;
        height: 50vh;
    }
`;

const UpperSettings = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
`;

const LeaderboardSettingsModal: React.FC<LeaderboardSettingsModalProps> = ({
    isOpen,
    onClose,
}) => {
    const {
        leaderboardType,
        setLeaderboardType,
        sort,
        setSort,
        country,
        setCountry,
    } = useLeaderboardStore();

    const { getAvailableCountries, getCountryByNumber } = useCountryStore();
    const countries = getAvailableCountries();

    const [search, setSearch] = useState('');

    const filteredCountries = countries.filter((country) => {
        return country[1].toLowerCase().includes(search.toLowerCase());
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalContainer>
                <h1>Leaderboard settings</h1>
                <UpperSettings>
                    <div>
                        <h2>Sort by:</h2>
                        <Dropdown
                            buttonText={`${sort}`}
                            content={
                                <>
                                    <DropdownItem
                                        onClick={() => setSort('total points')}
                                    >
                                        Total points
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() =>
                                            setSort('level points only')
                                        }
                                    >
                                        Level points only
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() =>
                                            setSort('extremes count')
                                        }
                                    >
                                        Extremes count
                                    </DropdownItem>
                                </>
                            }
                        />
                    </div>
                    <div>
                        <h2>Selected leaderboard:</h2>
                        <Dropdown
                            buttonText={leaderboardType}
                            content={
                                <>
                                    <DropdownItem
                                        onClick={() =>
                                            setLeaderboardType('players')
                                        }
                                    >
                                        Players
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() =>
                                            setLeaderboardType('countries')
                                        }
                                    >
                                        Countries
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() =>
                                            setLeaderboardType('clans')
                                        }
                                    >
                                        Clans
                                    </DropdownItem>
                                </>
                            }
                        />
                    </div>
                </UpperSettings>
                {leaderboardType === 'players' && (
                    <>
                        <h2>Filter by country</h2>
                        <Dropdown
                            buttonText={
                                getCountryByNumber(country)?.name ?? 'Global'
                            }
                            content={
                                <>
                                    <DropdownItem
                                        onClick={() => setCountry('0')}
                                    >
                                        Global
                                    </DropdownItem>

                                    <Search
                                        input={search}
                                        setinput={setSearch}
                                    />

                                    {filteredCountries.map((country, index) => (
                                        <DropdownItem
                                            key={`${country[0]}-${index}`}
                                            onClick={() =>
                                                setCountry(country[0])
                                            }
                                        >
                                            {country[1]}
                                        </DropdownItem>
                                    ))}
                                </>
                            }
                        />
                    </>
                )}
            </ModalContainer>
        </Modal>
    );
};

export { LeaderboardSettingsModal };
