import { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';
import { useQuery } from 'react-query';
import YoutubeEmbed from 'components/Others/YoutubeEmbed';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;
    padding: 0rem;
    overflow: hidden;
`;

const Section = styled.div`
    background-color: ${colors.backgroundDark};
    border-radius: 1rem;
    padding: 5rem;
    text-align: center;
    box-shadow: 0 0 10px ${colors.backgroundDark};
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 0rem;

    @media (max-width: 768px) {
        padding: 2rem;
        margin-bottom: 5rem;
    }
`;

const Heading = styled.h1`
    font-size: 2.5rem;
    color: ${colors.primary};
    margin-bottom: 3rem;

    @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 2rem;
    }
`;

const SubHeading = styled.p`
    color: ${colors.primaryLight};
    margin-bottom: 2.5rem;

    @media (max-width: 768px) {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }
`;

const LevelInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const VideoWrapper = styled.div`
    width: 100%;
    max-width: 560px;
    height: auto;
    border-radius: 1rem;
    overflow: hidden;
    margin-right: 2rem;
    flex-shrink: 0;

    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 1rem;
    }
`;

const VideoEmbed = styled.div`
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: 100%;
    background: #000;
    border-radius: 1rem;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 111%;
        height: 100%;
    }
`;

const LevelDetails = styled.div`
    flex-grow: 1;
    text-align: left;
    color: ${colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        align-items: center;
        text-align: center;
    }
`;

const LevelNameRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
`;

const LevelName = styled.div`
    font-size: 2rem;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const LevelId = styled.div`
    font-size: 1.5rem;
    color: ${colors.primaryLight};
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const LevelPublisher = styled.div`
    font-size: 1rem;
    color: ${colors.primaryLight};

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

const PercentageInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 2rem;

    @media (max-width: 768px) {
        align-items: center;
        margin-left: 0;
    }
`;

const PercentageInput = styled.input`
    font-size: 1rem;
    border: none;
    padding: 0.5rem;
    background-color: ${colors.backgroundSemiLight};
    border-radius: 0.5rem;
    box-shadow: ${colors.backgroundDark} 0px 8px 24px;
    color: ${colors.primary};
    margin-bottom: 1rem;
    width: 150px;

    &:focus {
        outline: none;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 3rem;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0.5rem;
    }
`;

const CustomButton = styled.button<{ bgColor?: string }>`
    background-color: ${props => props.bgColor || colors.backgroundSemiLight};
    color: ${colors.primary};
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
    }
`;

const DoneButton = styled(CustomButton)`
    background-color: ${colors.secondary};
    width: 175px;
    margin-left: 1rem;

    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
    }
`;

const LoadButton = styled(CustomButton)`
    background-color: ${colors.backgroundSemiLight};
`;

const SaveButton = styled(CustomButton)`
    background-color: ${colors.confirm};
`;

const ResetButton = styled(CustomButton)`
    background-color: ${colors.cancel};
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    margin-bottom: 2.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

const RangeInput = styled.input`
    flex: 1;
    font-size: 1.25rem;
    border: none;
    padding: 1em;
    background-color: ${colors.backgroundSemiLight};
    border-radius: 0.5rem;
    box-shadow: ${colors.backgroundDark} 0px 8px 24px;
    color: ${colors.primary};
    max-width: 100%;

    &:focus {
        outline: none;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 0.75em;
    }
`;

const StartButton = styled(CustomButton)`
    background-color: ${colors.secondary};
    color: ${colors.primary};
    width: 14rem;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 0.5rem;
    padding: 1.5rem;
    transition: transform 0.3s;
    margin-bottom: 1.5rem;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 768px) {
        width: 100%;
        font-size: 1rem;
        padding: 1rem;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin-top: -1rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

const CongratulationsMessage = styled.div`
    background-color: ${colors.backgroundDark};
    border-radius: 1rem;
    padding: 10rem;
    text-align: center;
    box-shadow: 0 0 10px ${colors.backgroundDark};
    color: ${colors.primary};
    margin-bottom: 0rem;

    h1 {
        font-size: 3.5rem;
        margin-bottom: 2rem;

        @media (max-width: 768px) {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
    }

    p {
        font-size: 2rem;

        @media (max-width: 768px) {
            font-size: 1.25rem;
        }
    }
`;

type Level = {
    level_id: number;
    rank: number;
    name: string;
    position: number;
    image: string;
    publisher: { global_name: string };
};

type LevelDetailsType = {
    publisher: { global_name: string };
    verification: { video_url: string };
};

const fetchRange = async (): Promise<Level[]> => {
    const response = await fetch('https://api.aredl.net/api/aredl/levels');
    const text = await response.text();
    console.log('Response text:', text);
    try {
        return JSON.parse(text);
    } catch (e) {
        console.error('Failed to parse JSON:', text);
        throw new Error('Invalid JSON response');
    }
};

const fetchLevelDetails = async (level_id: number): Promise<LevelDetailsType> => {
    const response = await fetch(
        `https://api.aredl.net/api/aredl/levels/${level_id}?verification=true&publishers=true`
    );
    if (!response.ok) {
        throw new Error('Failed to load level details');
    }
    return response.json();
};

const isPositiveInteger = (value: number | string): boolean => {
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return Number.isInteger(numberValue) && numberValue > 0;
};

const isTwoPlayerLevel = (levelName: string): boolean => {
    return levelName.toLowerCase().includes('(2p)');
};

const getRandomLevel = (levels: Level[], rangeStart: number | '', rangeEnd: number | '', excludedTwoPlayerLevels: Set<number>): Level | null => {
    let filteredLevels = levels;

    if (rangeStart !== '' && rangeEnd !== '') {
        filteredLevels = filteredLevels.filter(level => level.position >= rangeStart && level.position <= rangeEnd);
    } else if (rangeStart !== '') {
        filteredLevels = filteredLevels.filter(level => level.position >= rangeStart);
    } else if (rangeEnd !== '') {
        filteredLevels = filteredLevels.filter(level => level.position <= rangeEnd);
    }

    if (filteredLevels.length === 0) {
        return null;
    }

    const twoPlayerLevels = filteredLevels.filter(level => isTwoPlayerLevel(level.name));
    const nonTwoPlayerLevels = filteredLevels.filter(level => !isTwoPlayerLevel(level.name));

    let availableLevels = nonTwoPlayerLevels;

    if (availableLevels.length === 0) {
        availableLevels = twoPlayerLevels.filter(level => !excludedTwoPlayerLevels.has(level.level_id));
    } else {
        availableLevels = [...nonTwoPlayerLevels, ...twoPlayerLevels.filter(level => !excludedTwoPlayerLevels.has(level.level_id))];
    }

    if (availableLevels.length === 0) {
        return null;
    }

    const randomIndex = Math.floor(Math.random() * availableLevels.length);
    return availableLevels[randomIndex];
};

function Roulette() {
    const { data: levels, error, isLoading } = useQuery({
        queryKey: ['range'],
        queryFn: fetchRange
    });
    const [rangeStart, setRangeStart] = useState<number | ''>('');
    const [rangeEnd, setRangeEnd] = useState<number | ''>('');
    const [currentLevel, setCurrentLevel] = useState<Level | null>(null);
    const [currentPercentage, setCurrentPercentage] = useState(1);
    const [inputPercentage, setInputPercentage] = useState<string>('');
    const [inputValid, setInputValid] = useState<boolean>(true);
    const [rangeError, setRangeError] = useState<string>('');
    const [excludedTwoPlayerLevels, setExcludedTwoPlayerLevels] = useState<Set<number>>(new Set());

    const { data: levelDetails, refetch: refetchLevelDetails } = useQuery(
        ['levelDetails', currentLevel?.level_id],
        () => {
            if (currentLevel) {
                return fetchLevelDetails(currentLevel.level_id);
            }
        },
        {
            enabled: !!currentLevel, // Only fetch if there is a current level
        }
    );

    useEffect(() => {
        if (currentLevel) {
            refetchLevelDetails();
        }
    }, [currentLevel, refetchLevelDetails]);

    useEffect(() => {
        console.log('levelDetails:', levelDetails);
    }, [levelDetails]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{`Error loading data: ${(error as Error).message}`}</div>;

    const handleStart = () => {
        if (levels) {
            if ((!isPositiveInteger(rangeStart) && rangeStart !== '') ||
                (!isPositiveInteger(rangeEnd) && rangeEnd !== '') ||
                (rangeStart !== '' && rangeEnd !== '' && rangeStart > rangeEnd)) {
                setRangeError('Invalid range. Please enter a valid range.');
            } else {
                setRangeError('');
                const level = getRandomLevel(levels, rangeStart, rangeEnd, excludedTwoPlayerLevels);
                if (level) {
                    console.log('Image URL:', level.image);
                    setCurrentLevel(level);
                    setCurrentPercentage(1);
                    setInputValid(true);
                    updateExcludedTwoPlayerLevels(level.level_id, level.name);
                } else {
                    setRangeError('No levels available in the selected range.');
                }
            }
        }
    };

    const handleDone = () => {
        const newPercentage = parseInt(inputPercentage);
        if (levels && !isNaN(newPercentage) && newPercentage >= currentPercentage && newPercentage <= 100) {
            setCurrentPercentage(newPercentage + 1);
            const level = getRandomLevel(levels, rangeStart, rangeEnd, excludedTwoPlayerLevels);
            if (level) {
                console.log('Image URL:', level.image);
                setCurrentLevel(level);
                setInputPercentage(''); // Clear the input field
                setInputValid(true);
                updateExcludedTwoPlayerLevels(level.level_id, level.name);
            } else {
                setRangeError('No levels available in the selected range.');
            }
        } else {
            setInputValid(false); // Mark input as invalid
        }
    };

    const updateExcludedTwoPlayerLevels = (level_id: number, level_name: string) => {
        if (isTwoPlayerLevel(level_name)) {
            const newExcludedTwoPlayerLevels = new Set(excludedTwoPlayerLevels);
            newExcludedTwoPlayerLevels.add(level_id);
            setExcludedTwoPlayerLevels(newExcludedTwoPlayerLevels);
            console.log(`Excluded 2P Levels:`, Array.from(newExcludedTwoPlayerLevels));
        }
    };

   const getPublisher = () => {
    if (!levelDetails || !levelDetails.publisher || !levelDetails.publisher.global_name) {
        return 'Loading...';
    }
    return levelDetails.publisher.global_name;
};


    return (
        <Container>
            {currentPercentage > 100 ? (
                <CongratulationsMessage>
                    <Heading>Congratulations!</Heading>
                    <SubHeading>You have completed the entire Extreme Demon Roulette!</SubHeading>
                </CongratulationsMessage>
            ) : currentLevel ? (
                <Section>
                    <Heading>ROULETTE</Heading>
                    <LevelInfoContainer>
                        <VideoWrapper>
                            <VideoEmbed>
                                <YoutubeEmbed link={levelDetails?.verification?.video_url || ''} />
                            </VideoEmbed>
                        </VideoWrapper>
                        <LevelDetails>
                            <LevelNameRow>
                                <LevelName>#{currentLevel.position} - {currentLevel.name}</LevelName>
                            </LevelNameRow>
                            <LevelId>({currentLevel.level_id})</LevelId>
                            <LevelPublisher>Published by {getPublisher()}</LevelPublisher> {/* Use publisher from level details */}
                        </LevelDetails>
                        <PercentageInputContainer>
                            <PercentageInput
                                type="number"
                                placeholder={`AT LEAST ${currentPercentage}%`}
                                value={inputPercentage}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '' || !isNaN(parseFloat(value))) {
                                        setInputPercentage(value);
                                        setInputValid(true);
                                    } else {
                                        setInputPercentage('');
                                        setInputValid(false);
                                    }
                                }}
                                style={{ borderColor: inputValid ? 'initial' : 'red' }} // Highlight input if invalid
                            />
                            <DoneButton onClick={handleDone}>DONE</DoneButton>
                        </PercentageInputContainer>
                    </LevelInfoContainer>
                    <ButtonContainer>
                        <LoadButton>LOAD</LoadButton>
                        <SaveButton>SAVE</SaveButton>
                        <ResetButton>RESET</ResetButton>
                    </ButtonContainer>
                </Section>
            ) : (
                <Section>
                    <Heading>ROULETTE</Heading>
                    <SubHeading>Select the level range to continue: (Leave empty for all levels)</SubHeading>
                    <InputContainer>
                        <RangeInput
                            placeholder="Range Start"
                            value={rangeStart === '' ? '' : rangeStart.toString()}
                            onChange={(e) => setRangeStart(e.target.value ? parseInt(e.target.value) : '')}
                        />
                        <RangeInput
                            placeholder="Range End"
                            value={rangeEnd === '' ? '' : rangeEnd.toString()}
                            onChange={(e) => setRangeEnd(e.target.value ? parseInt(e.target.value) : '')}
                        />
                    </InputContainer>
                    {rangeError && <ErrorMessage>{rangeError}</ErrorMessage>}
                    <StartButton onClick={handleStart}>START</StartButton>
                    <ButtonContainer>
                        <LoadButton>LOAD</LoadButton>
                        <SaveButton>SAVE</SaveButton>
                        <ResetButton>RESET</ResetButton>
                    </ButtonContainer>
                </Section>
            )}
        </Container>
    );
}

export default Roulette;
