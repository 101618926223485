import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import colors from 'utils/style/colors';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const PaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
`;

const PageButton = styled.button`
    background-color: transparent;
    color: ${colors.primaryLight};
    border: ${colors.primaryLight} solid 0.05rem;

    &.active {
        background-color: ${colors.secondaryLight};
        color: ${colors.primary};
        border: ${colors.secondary} solid 0.05rem;
    }

    transition: background-color 0.5s;

    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    margin: 0 0.25rem;
    cursor: pointer;
    &:not(.active):hover {
        background-color: ${colors.backgroundLightTransparent};
        color: ${colors.primary};
        border-color: ${colors.primary};
    }
    &:disabled {
        border-color: ${colors.backgroundDark};
        cursor: not-allowed;
    }
`;

const PageInput = styled.input`
    width: 3rem;
    padding: 0.5rem;
    margin: 0 0.25rem;
    text-align: center;
    border: ${colors.primaryLight} solid 0.05rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: ${colors.primary};

    &:focus {
        outline: inherit;
    }
`;

const Pagination: React.FC<PaginationProps> = ({
    totalPages,
    currentPage,
    onPageChange,
}) => {
    const [isEditingStart, setIsEditingStart] = useState(false);
    const [isEditingEnd, setIsEditingEnd] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handlePageInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (/^\d*$/.test(event.target.value)) {
                setInputValue(event.target.value);
            }
        },
        [],
    );

    const handlePageInputBlur = useCallback(() => {
        const page = Number(inputValue);
        if (page > 0 && page <= totalPages) {
            onPageChange(page);
        }
        setIsEditingStart(false);
        setIsEditingEnd(false);
        setInputValue('');
    }, [inputValue, onPageChange, totalPages]);

    const renderPageNumbers = () => {
        const pages = [];
        const startPage = Math.max(2, currentPage - 1);
        const endPage = Math.min(totalPages - 1, currentPage + 1);

        if (currentPage > 2) {
            pages.push(
                isEditingStart ? (
                    <PageInput
                        key="start-input"
                        value={inputValue}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onChange={handlePageInputChange}
                        onBlur={handlePageInputBlur}
                        autoFocus
                    />
                ) : (
                    <PageButton
                        key="start-ellipsis"
                        onClick={() => setIsEditingStart(true)}
                    >
                        ...
                    </PageButton>
                ),
            );
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <PageButton
                    key={i}
                    className={i === currentPage ? 'active' : ''}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </PageButton>,
            );
        }

        if (currentPage < totalPages - 2) {
            pages.push(
                isEditingEnd ? (
                    <PageInput
                        key="end-input"
                        value={inputValue}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onChange={handlePageInputChange}
                        onBlur={handlePageInputBlur}
                        autoFocus
                    />
                ) : (
                    <PageButton
                        key="end-ellipsis"
                        onClick={() => setIsEditingEnd(true)}
                    >
                        ...
                    </PageButton>
                ),
            );
        }

        return pages;
    };

    return (
        <PaginationWrapper>
            <PageButton
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                &lt;
            </PageButton>
            <PageButton
                className={currentPage === 1 ? 'active' : ''}
                onClick={() => onPageChange(1)}
            >
                1
            </PageButton>
            {totalPages > 1 && renderPageNumbers()}
            {totalPages > 1 && (
                <PageButton
                    className={totalPages === currentPage ? 'active' : ''}
                    onClick={() => onPageChange(totalPages)}
                >
                    {totalPages}
                </PageButton>
            )}
            <PageButton
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                &gt;
            </PageButton>
        </PaginationWrapper>
    );
};

export default Pagination;
