import { BaseUser } from 'common/types/API';
import StyledLink from './StyledLink';
import styled from 'styled-components';
import colors from 'utils/style/colors';

interface UserProps {
    user: BaseUser;
    className?: string;
}

interface UsersProps {
    users: BaseUser[];
    className?: string;
}

const StyledUsersList = styled.div`
    display: flex;
    color: ${colors.primaryLight};
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 0.25rem;
    text-align: left;
    font-weight: normal;
    text-decoration: none;
    position: relative;
`;
export function UserLink(props: UserProps) {
    return (
        <StyledLink
            to={`/profile/${props.user.id}`}
            className={props.className}
        >
            {props.user.global_name}
        </StyledLink>
    );
}

export function UsersList(props: UsersProps) {
    return (
        <StyledUsersList>
            {props.users?.map((user, index) => (
                <div key={`${user}-${index}`}>
                    <UserLink user={user} />
                    {index < props.users.length - 1 ? ',' : ''}
                </div>
            ))}
        </StyledUsersList>
    );
}
