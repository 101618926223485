import styled from 'styled-components';
import colors from 'utils/style/colors';
import { useEffect, useRef } from 'react';
interface SwitchProps {
    options: string[];
    optionsDisplay?: string[];
    current: string;
    className?: string;
    set: (value: string) => void;
}

const StyledValueSwitch = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    align-items: center;

    h2 {
        color: ${colors.primaryLight};
        transition: color 0.25s;
        font-size: var(--dynamic-font-size, 2rem);
        font-weight: 600;
        overflow-x: visible;
        width: fit-content;
        align-self: center;
        white-space: nowrap;
        margin: 0;
        border: none;
        padding: 0;
        cursor: pointer;

        &.focus {
            color: ${colors.primary};
            text-decoration: underline;
            text-decoration-color: ${colors.secondary};
            text-decoration-thickness: 0.15rem;
            text-underline-offset: 0.5rem;
            width: fit-content;
        }
    }

    h2:not(focus):hover {
        color: ${colors.primary};
    }
`;

function ValueSwitch(props: SwitchProps) {
    const ref = useRef<HTMLDivElement>(null);

    const optionsDisplay = props.optionsDisplay ?? props.options;
    useEffect(() => {
        let resizeTimeout: NodeJS.Timeout;
        const handleResize = () => {
            if (resizeTimeout) clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                if (ref.current) {
                    const elementWidth = ref.current.offsetWidth;
                    const fontSize =
                        Math.min(Math.max(1, elementWidth / 200), 2) + 'rem';
                    ref.current.style.setProperty(
                        '--dynamic-font-size',
                        fontSize,
                    );
                }
            }, 5);
        };

        handleResize();

        const resizeObserver = new ResizeObserver(handleResize);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <StyledValueSwitch className={props.className} ref={ref}>
            {props.options.map((value, index) => (
                <h2
                    key={`${value}-${index}`}
                    onClick={() => props.set(value)}
                    className={value === props.current ? 'focus' : ''}
                >
                    {optionsDisplay[index].toUpperCase()}
                </h2>
            ))}
        </StyledValueSwitch>
    );
}

export default ValueSwitch;
