import styled from 'styled-components';
import colors from 'utils/style/colors';
import StyledButton from 'components/Layout/Button';
import Changelog from 'components/Home/Changelog';
import Editors from 'components/Home/Editors';
import Partner from 'components/Home/Partner';
import { FaGithub } from 'react-icons/fa6';

const Container = styled.div`
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media (min-width: 1000px) {
        grid-template-columns: 2.8fr 1.3fr;
    }
`;

const ButtonSectionContainer = styled.div`
    display: flex;
    background-color: ${colors.backgroundDarker};
    flex-direction: column;
    border-radius: 1rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: 1000px) {
        flex-direction: row;
    }

    section {
        text-align: center;
        &.github {
            text-align: left;
        }
        width: 100%;

        h1 {
            font-size: 2rem;
            color: ${colors.primary};
        }

        h2 {
            margin: 0;
        }

        p {
            margin: 0.5rem 0;
            font-size: 1rem;
            color: ${colors.primaryLight};
        }

        @media (min-width: 1000px) {
            width: 75%;
        }
    }
`;

const Section = styled.section`
    margin: 1rem 0;
    padding: 0.5rem;
    background-color: ${colors.backgroundDarker};
    border-radius: 1rem;
    width: 100%;

    p {
        margin-top: 0.25rem;
        margin-left: 0.5rem;
        font-size: 1.1rem;
    }
`;

const SectionHeading = styled.h2`
    margin: 0.5rem 0 0 0.5rem;
    font-size: 1.5rem;
    color: ${colors.primary};
`;

const PartnersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.5vw;

    @media (max-width: 1000px) {
        gap: 2vw;
    }
`;

const RestyledButton = styled(StyledButton)`
    font-size: 1.1rem;
`;

function Home() {
    const partners = [
        ['UDL', 'Unrated Demon List'],
        ['IDL', 'Insane Demon List'],
        ['EDEL', 'Extreme Demon Enjoyments List'],
        ['SFH', 'Song File Hub'],
        ['PEMONLIST', 'Pemonlist (Platformer List)'],
        ['GDDL', 'Geometry Dash Demon Ladder'],
    ];

    return (
        <Container>
            <div>
                <ButtonSectionContainer>
                    <section>
                        <h1>
                            WELCOME TO THE <strong>AREDL</strong>!
                        </h1>
                        <p>
                            The All Rated Extreme Demons List (AREDL) is a
                            community based on ranking every rated Extreme Demon
                            in Geometry Dash as accurately as we can.
                        </p>
                    </section>

                    <RestyledButton
                        onClick={() => (window.location.href = '/list')}
                    >
                        VIEW LIST
                    </RestyledButton>
                </ButtonSectionContainer>

                <Section>
                    <SectionHeading>CHANGELOG</SectionHeading>
                    <Changelog />
                </Section>

                <Section>
                    <SectionHeading>OTHER WEBSITES/PARTNERS</SectionHeading>
                    <p>
                        You can find below different lists/websites/ressources
                        integrated on our website and/or partnered with us!
                        Click on their icon for more information about them!
                    </p>
                    <PartnersWrapper>
                        {partners.map(([infoType, name]) => (
                            <Partner
                                icon={`/assets/partners/${infoType}.png`}
                                name={name}
                                infoType={infoType}
                                key={infoType}
                            />
                        ))}
                    </PartnersWrapper>
                </Section>

                <ButtonSectionContainer>
                    <section className="github">
                        <SectionHeading>THE AREDL GITHUB</SectionHeading>
                        <p>
                            All of the AREDL codebase can be found on our GitHub
                            organization. <br />
                            <br />
                            Head over there if you&apos;re curious about how the
                            website works, want to report a bug, or even
                            contribute to the project!
                            <br />
                            <br />
                        </p>
                    </section>
                    <RestyledButton
                        onClick={() =>
                            window.open(
                                'https://github.com/All-Rated-Extreme-Demon-List',
                                '_blank',
                            )
                        }
                    >
                        <FaGithub />
                        The Repository
                    </RestyledButton>
                </ButtonSectionContainer>
            </div>

            <Editors />
        </Container>
    );
}

export default Home;
